import { invokeApi } from "../../bl_libs/invokeApi";
export const AllExerciseListingApi = async (
  page,
  limt,
  search,
  data,
  status,
  selectedTab
) => {
  const requestObj = {
    path: `api/exercise/exercises?page=${page}&limit=${limt}&search=${search}&status=${status}&exercise_type=${data?.exercise_type}&sort=${selectedTab}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const ExerciseListingApi = async (
  page,
  limt,
  search,
  data,
  selectedTab
) => {
  const requestObj = {
    path: `api/exercise/active_exercise?page=${page}&limit=${limt}&search=${search}&exercise_type${data?.exercise_type}&sort=${selectedTab}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const exercise_detail_api = async (slug) => {
  const requestObj = {
    path: `api/exercise/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const recordingListApi = async (slug) => {
  const requestObj = {
    path: `api/lesson_recording/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const recordingDetailApi = async (slug) => {
  const requestObj = {
    path: `api/lesson_recording/recording_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddExerciseApi = async (data) => {
  const requestObj = {
    path: `api/exercise`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddLessonRecordingApi = async (data) => {
  const requestObj = {
    path: `api/lesson_recording/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditLessonRecordingApi = async (data, slug) => {
  const requestObj = {
    path: `api/lesson_recording/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditExerciseApi = async (data, slug) => {
  const requestObj = {
    path: `api/exercise/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteExerciseApi = async (slug) => {
  const requestObj = {
    path: `api/exercise/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const lessonRecordingDelete = async (slug) => {
  const requestObj = {
    path: `api/lesson_recording/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
