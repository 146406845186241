import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Radio,
  Backdrop,
  CircularProgress,
  Chip,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { makeStyles } from "@mui/styles";

import { Icon } from "@iconify/react";

import { useNavigate, useParams } from "react-router-dom";

// import MultipleChoiceOptions from "./component/MultipleChoiceOptions";

import EditTemplate from "./component/EditUI";

import {
  editFormTemplate,
  getTemplateDetail,
} from "src/DAL/FormTemplate/feedbackTemplates";
import RatingComponent from "./component/RaingStylesView";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import MultipleChoiceOptions from "./component/MultipleChoiceOptions";
import AvatarGallery from "./component/imageViewOnLength";
import ImageView from "./component/ImageView";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function EditFormTemplate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const param = useParams();
  const _id = param.id;
  const classes = useStyles();
  const [goNext, setGoNext] = useState(false);
  const [templateTitle, setTemplateTitle] = useState("");
  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState(["", ""]);
  const [rating, setRating] = useState(4);
  const [maximumRating, setMaximumRating] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [minimumRating, setMinimumRating] = useState("");
  const [questionsToSend, setQuestionsToSend] = useState([]);
  const [editShow, setEditShow] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [answerGeniusTitle, setAnswerGeniusTitle] = useState("");
  const [saveTemplate, setSaveTemplate] = useState(true);
  const [goToSaveTemplate, setGoToSaveTemplate] = useState(true);
  const [inputs, setInputs] = React.useState({
    question_type: "multiple_choice",
    question_statement: "",
    active_status: "",
    Type: "",
    description: "",
    question_type_required: false,
  });
  const answerGenius = [
    {
      title: "Agree-Disagree",
      data: ["Strongly agree", "Agree", "Disagree", "Strongly disagree"],
    },
    {
      title: "Exceeds-Meets",
      data: ["Exceeds", "Meets", "Need Improvement"],
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs.question_type == "multiple_choice") {
      const updatedList = [...questionsToSend];
      updatedList.push({
        title: inputs.question_statement,
        required: inputs.question_type_required,
        order: 1,
        type: inputs.question_type,
        options: multipleChoiceOptions,
        options_count: multipleChoiceOptions.length,
      });
      editShow.push({ display: false });
      setQuestionsToSend(updatedList);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["question_type_required"]: false,
        };
      });
      setMultipleChoiceOptions(["", ""]);
    }
    if (inputs.question_type == "multiple_select") {
      const updatedList = [...questionsToSend];
      updatedList.push({
        title: inputs.question_statement,
        required: inputs.question_type_required,
        order: 1,
        type: inputs.question_type,
        options: multipleChoiceOptions,
        options_count: multipleChoiceOptions.length,
      });
      editShow.push({ display: false });
      setQuestionsToSend(updatedList);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["question_type_required"]: false,
        };
      });
      setMultipleChoiceOptions(["", ""]);
    }
    if (inputs.question_type == "image") {
      const updatedList = [...questionsToSend];
      updatedList.push({
        title: inputs.question_statement,
        required: inputs.question_type_required,
        order: 1,
        type: inputs.question_type,
        options: [minimumRating, maximumRating],
        options_count: parseInt(rating),
      });
      editShow.push({ display: false });
      setQuestionsToSend(updatedList);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["question_type_required"]: false,
        };
      });
      setMinimumRating("");
      setMaximumRating("");
    }
    if (inputs.question_type == "free_text") {
      const updatedList = [...questionsToSend];
      updatedList.push({
        title: inputs.question_statement,
        required: inputs.question_type_required,
        order: 1,
        type: inputs.question_type,
        options: [],
        options_count: 0,
      });
      editShow.push({ display: false });
      setQuestionsToSend(updatedList);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["question_type_required"]: false,
        };
      });
    }
    if (inputs.question_type == "signature") {
      const updatedList = [...questionsToSend];
      updatedList.push({
        title: inputs.question_statement,
        required: inputs.question_type_required,
        order: 1,
        type: inputs.question_type,
        options: [],
        options_count: 0,
      });
      editShow.push({ display: false });
      setQuestionsToSend(updatedList);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["question_type_required"]: false,
        };
      });
    }
    setAnswerGeniusTitle("");
    setSaveTemplate(true);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setGoNext(true);
    // console.log("handleNext");
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleBack = () => {
    navigate(-1);
  };
  const handleChangeInMultipleChoice = (e, index) => {
    if (event.target.value[0] === " ") {
      return;
    }
    multipleChoiceOptions[index] = e.target.value;
    setMultipleChoiceOptions([...multipleChoiceOptions]);
    // console.log(e.target.value, index, "dlksfjiosdjifsdo");
  };
  const handelAddChoice = (index) => {
    let list = multipleChoiceOptions;
    list.splice(index + 1, 0, "");
    setMultipleChoiceOptions([...list]);
  };

  const handelDelChoice = (index) => {
    if (multipleChoiceOptions.length === 2) {
      return;
    }
    let list = multipleChoiceOptions;
    list.splice(index, 1);
    setMultipleChoiceOptions([...list]);
  };
  const handelDelQuestion = () => {
    let index = deleteData;

    // console.log(index, "kjsdjk");
    let list = questionsToSend;
    let list2 = editShow;
    list2.splice(index, 1);
    list.splice(index, 1);
    setEditShow([...list2]);
    setQuestionsToSend([...list]);
    setOpenDelete(false);
  };
  const handleAgreeDelete = (value) => {
    // console.log(value, "value");
    setDeleteData(value);
    setOpenDelete(true);
  };
  const handelEditQuestions = (index) => {
    editShow[index].display = true;
    setEditShow([...editShow]);
    setGoToSaveTemplate(false);
    setSaveTemplate(true);
  };
  const handleChangeAnswerGenius = (event) => {
    setAnswerGeniusTitle(event.target.value);
    answerGenius.filter((ans) => {
      if (ans.title == event.target.value) {
        // console.log(ans, "sdfklks");
        setMultipleChoiceOptions(ans.data);
      }
    });
  };
  const handelcloseQuestionsEntry = () => {
    setInputs((values) => ({
      ...values,
      ["question_type"]: "multiple_choice",
      ["question_statement"]: "",
      ["question_type_required"]: false,
    }));
    setMinimumRating("");
    setMaximumRating("");
    setMultipleChoiceOptions(["", ""]);
    setAnswerGeniusTitle("");
    setRating("");
    // setGoToSaveTemplate(false);
    setSaveTemplate(true);
  };
  const handelSendDataToApi = async (event) => {
    // console.log("dsflkjdsk");
    // event.preventDefault();
    setOpenBackdrop(true);
    let postData = {
      title: templateTitle,
      questions: questionsToSend,
      status: inputs?.active_status,
      receiver_type: inputs?.Type,
      description: inputs?.description,
    };
    // console.log(_id, postData, "flkjlasfi");

    try {
      const res = await editFormTemplate(_id, postData);
      if (res.code === 200) {
        navigate(-1);
        // showAddedDataWithoutApi(res.company, companies, setCompanies);
        // setCount(count + 1);
        // handleClose();

        enqueueSnackbar(res.message, { variant: "success" });
      } else {
        setOpenBackdrop(false);
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setOpenBackdrop(false);
    }
  };
  async function getDetailsOfTemplate() {
    try {
      const res = await getTemplateDetail(_id);
      if (res.code === 200) {
        console.log(res, "kjdsah");
        let data = [];
        res?.form_template?.questions.map((item) => {
          data.push({ display: false });
        });
        setEditShow(data);
        setTemplateTitle(res?.form_template?.title);
        setQuestionsToSend(res?.form_template?.questions);

        setInputs({
          ["active_status"]: res?.form_template?.status,
          ["Type"]: res?.form_template?.receiver_type,
          ["description"]: res?.form_template?.description,
          ["question_type"]: "multiple_choice",
          ["question_type_required"]: false,
        });
        // console.log(res?.support_ticket?.emp_obj_id, "getMySupportDetail");
        // return res;
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenBackdrop(false);
    }
  }
  useEffect(() => {
    getDetailsOfTemplate();
  }, []);
  useEffect(() => {
    console.log(editShow.length, "sdkfs");
    let count = 0;
    editShow.map((item, index) => {
      if (item.display) {
        count--;
      } else {
        count++;
      }
    });
    if (count == editShow.length) {
      setGoToSaveTemplate(true);
    } else {
      setGoToSaveTemplate(false);
    }
  }, [editShow]);
  //===================================================================
  if (openBackdrop == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Container sx={{ width: { sm: 950, md: 950 } }}>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handelDelQuestion}
      />
      {/* <Backdrop
        invisible="true"
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop> */}
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
      </Stack>{" "}
      <h2>Edit Template</h2>
      <>
        {/* <form onSubmit={handelSendDataToApi} autoComplete="off"> */}
        <Stack
          direction="column"
          alignItems="center"
          spacing={1.5}
          justifyContent="space-between"
          mb={4.5}>
          <div className="w-100">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ width: "100%", mr: 1 }}
              // fullWidth
              InputProps={{ inputProps: { min: 5 } }}
              required
              name="title"
              value={templateTitle}
              onChange={(e) => {
                setTemplateTitle(e.target.value);
              }}
            />
            <FormControl sx={{ width: "49%", mt: 2, mr: 1 }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="active_status"
                value={inputs.active_status}
                label="Status"
                onChange={handleChange}>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "50%", mt: 2 }} required>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="Type"
                value={inputs.Type}
                label="Type"
                onChange={handleChange}>
                <MenuItem value="member">Member</MenuItem>
                <MenuItem value="program">Program</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="outlined-basic"
              label="Description"
              multiline
              variant="outlined"
              sx={{ width: "100%", mt: 2 }}
              // fullWidth

              value={inputs.description}
              rows={4}
              required
              name="description"
              onChange={(e) => {
                if (e.target.value[0] === " ") {
                  return;
                }
                handleChange(e);
              }}
            />
          </div>

          {questionsToSend &&
            questionsToSend.map((item, index) => {
              // console.log(item, "afsoiusd", editShow[index].display);
              return !editShow[index].display ? (
                <Card
                  className="w-100"
                  sx={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    mb: 1,
                    width: { sm: "100%", md: "100%" },
                    margin: "auto",
                    p: 2,
                    backgroundColor: "#f5f5f2",
                    position: "relative",
                  }}>
                  {item?.required && (
                    <Chip
                      label={"Required"}
                      variant="contained"
                      color={"error"}
                      sx={{
                        fontSize: "10px",
                        height: "auto",
                        width: "auto",
                        position: "absolute",
                        top: 8,
                        left: 16,
                      }}
                    />
                  )}
                  <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                      mt={1}>
                      <Grid item md={12} xs={12}>
                        <div className="d-flex align-items-center mb-2">
                          <div className="">
                            <Typography
                              sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}>
                              Q{index + 1}.
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: 14, mr: 1 }}>
                              {item?.title}
                            </Typography>
                          </div>
                        </div>
                      </Grid>

                      {item?.type == "multiple_choice" ? (
                        <Grid item md={12} xs={12}>
                          {item?.options.map((item) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <Radio disabled />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                    }}>
                                    {item}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                          <div className="w-100 mt-2 d-flex justify-content-end">
                            <Button
                              variant="outlined"
                              sx={{ mr: 1 }}
                              onClick={() => {
                                handelEditQuestions(index);
                              }}>
                              Edit
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                handleAgreeDelete(index);
                              }}>
                              Delete
                            </Button>
                          </div>
                        </Grid>
                      ) : item?.type == "multiple_select" ? (
                        <Grid item md={12} xs={12}>
                          {item?.options.map((item) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <Radio disabled />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                    }}>
                                    {item}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                          <div className="w-100 mt-2 d-flex justify-content-end">
                            <Button
                              variant="outlined"
                              sx={{ mr: 1 }}
                              onClick={() => {
                                handelEditQuestions(index);
                              }}>
                              Edit
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                handleAgreeDelete(index);
                              }}>
                              Delete
                            </Button>
                          </div>
                        </Grid>
                      ) : item?.type == "image" ? (
                        <Grid item md={12} xs={12}>
                          <div className="d-flex align-items-center mb-2">
                            <div className="">
                              <Typography
                                sx={{
                                  fontWeight: "500",
                                  fontSize: 14,
                                  mr: 1,
                                }}>
                                {item?.options[0]}
                              </Typography>
                            </div>
                            {/* <div>
                                <Rating
                                  name="read-only"
                                  value={0}
                                  max={item?.options_count}
                                  readOnly
                                />
                              </div> */}
                            {/* <RatingComponent
                              rating={0}
                              onRatingChange={() => {}}
                              details={true}
                              maxLength={item?.options_count}
                            /> */}
                            <AvatarGallery length={item?.options_count} />

                            <div className="">
                              <Typography
                                sx={{
                                  fontWeight: "500",
                                  fontSize: 14,
                                  ml: 1,
                                }}>
                                {item?.options[1]}
                              </Typography>
                            </div>
                          </div>
                          <div className="w-100 mt-2 d-flex justify-content-end">
                            <Button
                              variant="outlined"
                              sx={{ mr: 1 }}
                              onClick={() => {
                                handelEditQuestions(index);
                              }}>
                              Edit
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                handleAgreeDelete(index);
                              }}>
                              Delete
                            </Button>
                          </div>
                        </Grid>
                      ) : item?.type == "free_text" ? (
                        <Grid item md={12} xs={12}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            disabled
                            rows={4}
                            sx={{ width: "100%" }}
                          />
                          <div className="w-100 mt-2 d-flex justify-content-end">
                            <Button
                              variant="outlined"
                              sx={{ mr: 1 }}
                              onClick={() => {
                                handelEditQuestions(index);
                              }}>
                              Edit
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                handleAgreeDelete(index);
                              }}>
                              Delete
                            </Button>
                          </div>
                        </Grid>
                      ) : (
                        item?.type == "signature" && (
                          <Grid item md={12} xs={12}>
                            <TextField
                              id="outlined-multiline-static"
                              multiline
                              disabled
                              rows={4}
                              sx={{
                                width: "100%",
                                "& fieldset": {
                                  top: 0,
                                },
                                "& legend": {
                                  display: "none",
                                },
                              }}
                            />
                            {/* <div>
                                <SignatureCanvas
                                  // ref={signatureRef}
                                  canvasProps={{
                                    width: "100%",
                                    height: 200,
                                    className: "signature-canvas",
                                    style: {
                                      width: "100%",
                                      height:"110px",
                                      border: "2px solid #000",
                                      borderRadius: "10px",
                                    },
                                  }}
                                  backgroundColor="white"
                                  penColor="black"
                                />
                              </div> */}

                            <div className="w-100 mt-2 d-flex justify-content-end">
                              <Button
                                variant="outlined"
                                sx={{ mr: 1 }}
                                onClick={() => {
                                  handelEditQuestions(index);
                                }}>
                                Edit
                              </Button>{" "}
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  handleAgreeDelete(index);
                                }}>
                                Delete
                              </Button>
                            </div>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                </Card>
              ) : (
                <EditTemplate
                  questionsToSend={questionsToSend}
                  index={index}
                  answerGenius={answerGenius}
                  setQuestionsToSend={setQuestionsToSend}
                  editShow={editShow}
                  setGoToSaveTemplate={setGoToSaveTemplate}
                  setEditShow={setEditShow}
                />
              );
            })}
          {!saveTemplate ? (
            <Card
              className="w-100"
              sx={{
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                mb: 1,
                width: { sm: "100%", md: "100%" },
                margin: "auto",
                p: 2,
              }}>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                    mt={1}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Question Statement"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        // fullWidth
                        required
                        name="question_statement"
                        value={inputs.question_statement}
                        onChange={(event) => {
                          if (event.target.value[0] === " ") {
                            return;
                          }
                          setInputs((values) => ({
                            ...values,
                            [event.target.name]: event.target.value,
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item md={4.5} xs={12}>
                      <FormControl sx={{ mb: 0, width: "100%" }} required>
                        <InputLabel id="demo-simple-selec-label" size="small">
                          Question Type
                        </InputLabel>
                        <Select
                          size="small"
                          required
                          labelId="demo-simple-selec-label"
                          id="demo-simple-select"
                          name="question_type"
                          value={inputs?.question_type}
                          label="Question Type"
                          onChange={handleChange}>
                          <MenuItem value="multiple_choice">
                            Multiple Choice (single select)
                          </MenuItem>
                          <MenuItem value="multiple_select">
                            Multiple Choice (Multiple select)
                          </MenuItem>
                          <MenuItem value="free_text">Text</MenuItem>
                          <MenuItem value="image">Image</MenuItem>
                          <MenuItem value="signature">Signature</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <FormControl sx={{ mb: 0, width: "100%" }} required>
                        <InputLabel id="demo-simple-selec-label1" size="small">
                          Is it Requird?
                        </InputLabel>
                        <Select
                          size="small"
                          required
                          labelId="demo-simple-selec-label1"
                          id="demo-simple-select"
                          name="question_type_required"
                          value={inputs?.question_type_required}
                          label="Is it Requird"
                          onChange={handleChange}>
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{ mb: 1 }}>
                      <hr className="mb-0 " />
                    </Grid>
                    {inputs?.question_type == "multiple_choice" ? (
                      <MultipleChoiceOptions
                        answerGeniusTitle={answerGeniusTitle}
                        handleChangeAnswerGenius={handleChangeAnswerGenius}
                        answerGenius={answerGenius}
                        multipleChoiceOptions={multipleChoiceOptions}
                        handleChangeInMultipleChoice={
                          handleChangeInMultipleChoice
                        }
                        handelAddChoice={handelAddChoice}
                        handelDelChoice={handelDelChoice}
                      />
                    ) : inputs?.question_type == "multiple_select" ? (
                      <MultipleChoiceOptions
                        answerGeniusTitle={answerGeniusTitle}
                        handleChangeAnswerGenius={handleChangeAnswerGenius}
                        answerGenius={answerGenius}
                        multipleChoiceOptions={multipleChoiceOptions}
                        handleChangeInMultipleChoice={
                          handleChangeInMultipleChoice
                        }
                        handelAddChoice={handelAddChoice}
                        handelDelChoice={handelDelChoice}
                      />
                    ) : inputs?.question_type == "image" ? (
                      <ImageView rating={rating} setRating={setRating} />
                    ) : inputs?.question_type == "signature" ? (
                      <Grid item md={12} xs={12}>
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          disabled
                          rows={6}
                          sx={{
                            width: "100%",
                            "& fieldset": {
                              top: 0,
                            },
                            "& legend": {
                              display: "none",
                            },
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item md={12} xs={12}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Answer"
                          multiline
                          disabled
                          rows={6}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    )}

                    <Grid item md={12} xs={12}>
                      <hr className="m-0 " />
                    </Grid>
                  </Grid>

                  <div className="col-12 mt-3 text-end">
                    <Button
                      sx={{
                        mr: 1,
                      }}
                      variant="contained"
                      type="submit"
                      startIcon={<Icon icon="eva:plus-fill" />}>
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Icon icon="ic:twotone-close-fullscreen" />}
                      onClick={() => handelcloseQuestionsEntry()}>
                      Close
                    </Button>
                  </div>
                </Box>
              </form>
            </Card>
          ) : (
            goToSaveTemplate && (
              <div className="w-100 mt-3 text-end">
                <Button
                  variant="contained"
                  onClick={() => setSaveTemplate(false)}
                  sx={{
                    mr: 1,
                  }}
                  startIcon={<Icon icon="eva:plus-fill" />}>
                  Add Question
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Icon icon="eva:plus-fill" />}
                  onClick={() => {
                    handelSendDataToApi();
                  }}>
                  Save Template
                </Button>
              </div>
            )
          )}
        </Stack>
        {/* </form> */}
      </>
    </Container>
  );
}
