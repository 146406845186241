import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  hv1Image,
  hv2Image,
  hv3Image,
  hv4Image,
  hv5Image,
  hv6Image,
} from "src/assets";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { programmesListing } from "../../DAL/Programmes/Programmes";
import {
  helpingVideoAgainstCategory,
  videos_listing,
} from "src/DAL/HelpingVideos/AdminHelpingVideos";
import RecordNotFound from "src/components/RecordNotFound";

export default function HelpingVideos() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState("");
  const [adminVideos, setAdminVideos] = useState([]);
  const programsList = async () => {
    const postData = {
      search: "",
    };
    const result = await helpingVideoAgainstCategory(postData, id);
    if (result.code == 200) {
      setAdminVideos(result.helping_videos);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    programsList();
  }, []);
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <h2>Helping videos </h2>
        </div>

        {adminVideos.length > 0 ? (
          adminVideos.map((value, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div
                  className="card mt-4 pods-cards-shadow cursor h-100"
                  onClick={() =>
                    navigate(
                      `/helping_videos_category/${id}/helping-videos/${value.helping_video_slug}`,
                      {
                        state: value,
                      }
                    )
                  }
                >
                  <img
                    src={s3baseUrl + value.thumbnail_image.thumbnail_1}
                    className="card-img-top pods-image"
                    alt="image"
                  />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="h2-heading">{value.title}</h3>
                      </div>

                      <div className="col-2 menu-option"></div>
                    </div>
                    <p className="programme-card-desc">
                      {value.short_description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <RecordNotFound title="Videos" />
        )}
      </div>
    </div>
  );
}
