import { invokeApi } from "../../bl_libs/invokeApi";
export const _upload_S3image = async (data) => {
  const requestObj = {
    path: `api/app_api/upload_image_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _upload_any_single_file_on_s3 = async (data) => {
  const requestObj = {
    path: `app/upload_any_single_file_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _del_any_file_on_s3 = async (data) => {
  const requestObj = {
    path: `app/delete_files_from_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
