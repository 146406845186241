import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

export default function AvatarGallery({ length }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleAvatarHover = (index) => {
    setHoveredIndex(index);
  };

  const handleAvatarLeave = () => {
    setHoveredIndex(null);
  };

  const handleImageUpload = (index) => {
    // Implement the image upload functionality here for the avatar at index
    console.log(`Image upload clicked for avatar ${index + 1}`);
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {[...Array(length)].map((_, index) => (
        <div
          key={index}
          style={{ position: "relative" }}
          onMouseEnter={() => handleAvatarHover(index)}
          onMouseLeave={handleAvatarLeave}
        >
          <Avatar
            sx={{ height: 100, width: 100 }}
            alt={`+`}
            src={`url_for_avatar_${index + 1}.jpg`}
            variant="rounded"
          />
          {/* {hoveredIndex === index && (
            <IconButton
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              color="primary"
              component="span"
              onClick={() => handleImageUpload(index)}
            >
              <PhotoCameraIcon />
            </IconButton>
          )} */}
        </div>
      ))}
    </div>
  );
}
