// material
import { Box, Grid, Container, Typography } from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentSettingState";
// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";

import { bgConsImage } from "src/assets";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { userInfo } = useContentSetting();
  // console.log(userInfo, "userInfouserInfouserInfo");

  return (
    // <div className="container">
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <img
        src={bgConsImage}
        alt="under construction"
        style={{ height: "468px" }}
      />
    </div>
    // </div>
    // <Page title="Dashboard | Minimal-UI">
    // <Container maxWidth="xl">
    //   <Box sx={{ pb: 5 }} className="center-text">
    //     {/* <Typography variant="h4">Hi, Welcome back</Typography> */}
    //     <h1 className="DashboardHeading">Hi, Welcome To Dashboard</h1>
    //   </Box>
    //   <Grid container spacing={3}>
    //     {/* <Grid item xs={12} sm={6} md={3}>
    //       <AppWeeklySales />
    //     </Grid>
    //     <Grid item xs={12} sm={6} md={3}>
    //       <AppNewUsers />
    //     </Grid>
    //     <Grid item xs={12} sm={6} md={3}>
    //       <AppItemOrders />
    //     </Grid>
    //     <Grid item xs={12} sm={6} md={3}>
    //       <AppBugReports />
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={12}>
    //       <DashboardCalender />
    //     </Grid> */}

    //     {/*  <Grid item xs={12} md={6} lg={8}>
    //         <AppWebsiteVisits />
    //       </Grid>

    //       <Grid item xs={12} md={6} lg={4}>
    //         <AppCurrentVisits />
    //       </Grid>

    //       <Grid item xs={12} md={6} lg={8}>
    //         <AppConversionRates />
    //       </Grid>

    //       <Grid item xs={12} md={6} lg={4}>
    //         <AppCurrentSubject />
    //       </Grid>

    //       <Grid item xs={12} md={6} lg={8}>
    //         <AppNewsUpdate />
    //       </Grid>

    //       <Grid item xs={12} md={6} lg={4}>
    //         <AppOrderTimeline />
    //       </Grid>

    //       <Grid item xs={12} md={6} lg={4}>
    //         <AppTrafficBySite />
    //       </Grid>

    //       <Grid item xs={12} md={6} lg={8}>
    //         <AppTasks />
    //       </Grid> */}
    //   </Grid>
    // </Container>
    // </Page>
  );
}
