import { invokeApi } from "src/bl_libs/invokeApi";
export const getNotes = async (id) => {
  const requestObj = {
    path: `api/notepad/get_program_notes?programId=${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getNotesProgramCalandar = async (userId, progId) => {
  const requestObj = {
    path: `api/notepad/get_member_notes?userId=${userId}&programId=${progId}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getmemberProgrammingNotes = async (id) => {
  const requestObj = {
    path: `api/notepad/get_notes?userId=${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addNotes = async (data) => {
  const requestObj = {
    path: `api/notepad/program_notes`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const inMemberProgramApiFroCalendar = async (data) => {
  const requestObj = {
    path: `api/notepad/add_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addmmeberNoteApi = async (data) => {
  const requestObj = {
    path: `api/notepad/addMemberNote`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
