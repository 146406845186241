import React from "react";
import { useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import MainPart from "./MainPart";
import GroupsFeeds from "./GroupsFeeds";

export default function MobilesTabs({
  showInfoBox,
  setShowInfoBox,
  userInfo,
  handleMyPosts,
}) {
  const [tabValue, setTabValue] = useState(0);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "General Community Area",
      component: (
        <>
          <MainPart
            showInfoBox={showInfoBox}
            setShowInfoBox={setShowInfoBox}
            userInfo={userInfo}
            event_id=""
          />
        </>
      ),
    },
    {
      title: "Groups",
      component: <GroupsFeeds />,
    },
  ];

  return (
    <>
      {tabValue == 0 ? (
        <>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <h2>General Community Area</h2>
            </div>
            <div className="col-lg-6 col-sm-12 text-end">
              <button
                className="small-contained-button"
                onClick={handleMyPosts}>
                {" "}
                My Posts
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2>Community Groups</h2>
          </div>
        </div>
      )}

      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
        // className="mt-4"
      />
    </>
  );
}
