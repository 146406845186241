import React, { useState, useEffect, useRef } from "react";
// import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Tooltip,
  Typography,
  Chip,
  IconButton,
  Modal,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { eventsListing } from "src/DAL/Calendar/Calendar";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

import FullPagePopup from "src/components/FormsDialog/FullPagePopup";
import DetailPopUpModel from "src/components/Calender/DetailPopUpModel";

import DayCalendar from "src/pages/Calender/DayCalendar";
import WeekCalendar from "src/pages/Calender/WeekCalendar";

import EditWorkoutPopup from "src/pages/Calender/component/EditWorkoutPopup";
// import CustomConfirmation from "../ModalPopover/Confirmation";
import {
  add_edit_program_workout_member_api,
  copy_paste_member_workout,
  delete_program_workout_Api,
  delete_program_workout_client_Api,
  reorder_events_api,
  reorder_member_events_api,
} from "src/DAL/programmeWorkout/programmeWorkout";

import { Icon } from "@iconify/react";
import { get_root_value } from "src/utils/domUtils";
import CustomConfirmation from "../ModalPopover/Confirmation";
import EditWorkoutPopupClient from "src/pages/Calender/EditWorkoutPopupClient";
import AddWorkoutPopup from "src/pages/Calender/component/AddWorkoutPopup";
import AddWorkoutPopupClient from "src/pages/Calender/component/AddWorkoutPopupClient";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { is_small_medium_screen } from "src/utils/constant";
import { s3baseUrl } from "src/config/config";
import TextEditor from "./GeneralQuestions/TextEditor";
import {
  addmmeberNoteApi,
  getmemberProgrammingNotes,
  getNotesProgramCalandar,
  inMemberProgramApiFroCalendar,
} from "src/DAL/notes";
import CloseIcon from "@mui/icons-material/Close";
import GeneralCkeditor from "./GeneralCkeditor";

const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  // if(calendarValue=="month")

  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function GeneralCalendarClient({
  programWorkoutList,
  reorderedWorkoutList,
  setCurrentDate,
  showAddEvent,
  time_zone,
  currentDate,
  setIsLoading,
  isLoading,
  user_type,
  reloadList,
  calendar_title,
  hideArrowsAndButtons,
  programme,
  member_id,
  member_vdot,
  user_id,
  type_member,
}) {
  const INITIAL_VALUES = {
    title: "",
    description: "",
    categories: [],
    status: true,
    image: null,
    imagePreview: null,
    videoURL: "",
  };
  console.log(user_id, "user_iduser_iduser_id");
  const [events, setEvents] = useState([]);
  const [inputs, setInputs] = useState(INITIAL_VALUES);

  const navigate = useNavigate();
  const classes = useStyles();
  const [eventDetailData, setEventDetailData] = useState({});
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);

  const [popupState, setPopupState] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [drawerStateUpdate, setDrawerStateUpdate] = useState(false);
  const [openItration, setopenItration] = useState(false);
  const [selectedTool, setSelectedTool] = useState("month");
  const [openDelete, setOpenDelete] = useState(false);
  const [openDragConfig, setOpenDragConfig] = useState(false);
  const [DragConfigDate, setDragConfigDate] = useState(false);
  const [DragConfigData, setDragConfigData] = useState(false);
  const [hoverdInfo, setHoverdInfo] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [editItrationDrawerState, setEditItrationDrawerState] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [selectedDate, setSelectDate] = useState("");
  const [dataDel, setDataDel] = useState();
  const [editWorkputData, setEditWorkputData] = useState();
  const calendarRef = useRef(null);
  const [dataCopy, setDataCopy] = useState();
  const [selectedWorkouts, setSelectedWorkouts] = useState([]);
  const [pastedWorkouts, setPastedWorkouts] = useState([]);
  const [open, setOpen] = useState(false); // State to control dialog visibility

  const member = useParams();
  const handleOpen = () => {
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleDeleteEvent = (eventToDelete) => {
    // Handle deletion logic here
  };

  const getNotesList = async () => {
    const result = type_member
      ? await getNotesProgramCalandar(member.id, programme._id._id)
      : await getmemberProgrammingNotes(member.id);

    if (result.code === 200) {
      setInputs((oldInputs) => ({
        ...oldInputs,
        description: result?.note || result?.notes?.content,
      }));
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getNotesList();
  }, []);

  const handleAddNote = async () => {
    let postData = {
      content: inputs.description,
      userId: member.id,
    };

    if (type_member) {
      postData.programId = programme._id._id;
    }

    const result = type_member
      ? await inMemberProgramApiFroCalendar(postData)
      : await addmmeberNoteApi(postData);

    if (result.code === 200) {
      handleClose();
      getNotesList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCellHover = (info) => {
    setHoveredDate(info.date);
    setHoverdInfo(info);
  };

  const handleCellUnhover = (info) => {
    // setHoveredDate(null);
  };
  const isFirstEventOnDate = (test) => {
    let date = moment(test.workout_date).format("YYYY-MM-DD");
    const sameDateEvents = events.filter((e) => e.date === date);
    return sameDateEvents[0]._id === test._id;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };
  const handleOpenEditor = () => {
    setOpenEditor(true);
  };
  const handleCloseEditor = () => {
    setOpenEditor(false);
  };

  const isLastEventOnDate = (test) => {
    let date = moment(test.workout_date).format("YYYY-MM-DD");
    const sameDateEvents = events.filter((e) => e.date === date);
    return sameDateEvents[sameDateEvents.length - 1]._id === test._id;
  };
  const isWorkoutSelected = (workout) =>
    selectedWorkouts.some((item) => item._id === workout._id) ||
    (pastedWorkouts && pastedWorkouts.some((item) => item._id === workout._id));
  const handleScrollCalled = (data) => {
    console.log("scroll called");
    const todayCell = document.querySelector(
      `.calendar-workout-card[id="${data}"]`
    ); // Find the DOM element corresponding to today's date

    todayCell.scrollIntoView({ behavior: "instant", block: "center" });
  };
  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };
  const handleCopyExercise = (data) => {
    setEditWorkputData();
    setPastedWorkouts();
    setSelectedWorkouts((prevSelected) => {
      const updatedSelected = prevSelected.some(
        (workout) => workout._id === data._id
      )
        ? prevSelected.filter((workout) => workout._id !== data._id)
        : [...prevSelected, data];

      // Set `setDataCopy(true);` only if there are selected workouts
      if (updatedSelected.length > 0) {
        setDataCopy(true);
      } else {
        setDataCopy(false);
      }

      return updatedSelected;
    });
  };
  const handleOpenDrawer = (arg) => {
    // setPastedWorkouts([]);
    // setSelectedWorkouts([]);
    setSelectDate(arg);
    setDrawerState(true);
  };

  const handlePaste = async (arg) => {
    // setSelectDate(arg);
    const workoutsIds = selectedWorkouts.map((workout) => workout._id);
    const formDataObject = {
      copiedWorkoutIds: workoutsIds,
      targetStartDate: moment(arg).format("YYYY-MM-DD"),
      member_id: member_id,
    };

    console.log(formDataObject, "formDataObject");

    const result = await copy_paste_member_workout(formDataObject);
    if (result.code === 200) {
      setPastedWorkouts(result?.copiedWorkouts);
      setSelectedWorkouts([]);

      enqueueSnackbar(result.message, { variant: "success" });
      setDataCopy(false);
      const workout = result?.copiedWorkouts.map((workout, i) => {
        return {
          ...workout,
          title: workout.title ? workout.title : "",
          color: workout.program_info?.color,
          workout_color: workout.program_info?.color,
          workout_title: workout.title ? workout.title : "",
          workout_exercises: workout.exercises,
          workout_program: workout.program_info,
          is_deleteAble: workout?.workout_has_logs ? false : true,
          workout_completed: workout?.workout_completed,
          status: workout.status,
          open: true,
          date: moment(workout.workout_date).format("YYYY-MM-DD"),
          index: events.length + i,
          editable: workout?.workout_has_logs ? false : true,
        };
      });
      console.log(workout, "workoutworkoutworkout");
      let list = [...events, ...workout];
      console.log(list, "listlistlist");
      setEvents(list);
      setTimeout(() => {
        handleScrollCalled(result?.copiedWorkouts[0]?._id);
      });
    } else {
      setSelectedWorkouts([]);
      enqueueSnackbar(result.message, { variant: "error" });
      setDataCopy(false);
    }
  };
  console.log(events, "eventssss");
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const eventDetail = (event) => {
    setEventDetailData(event);
    setPopupState(true);
  };
  const handleAgreeDelete = (data) => {
    // setPastedWorkouts([]);
    // setSelectedWorkouts([]);
    setDataDel(data);
    setOpenDelete(true);
  };
  const handleUpdate = () => {
    handleOpenEditDrawer();
  };
  const handleOpenEditIterationDrawer = () => {
    setEditItrationDrawerState(true);
  };
  const handleUpdateIteration = (data) => {
    setEditWorkputData(data);
    handleOpenEditIterationDrawer();
  };
  const closeDragConfig = async (value) => {
    setOpenDragConfig(false);
    getEventListing(programWorkoutList, time_zone);
    // reloadList();
  };
  const handleDelete = async (value) => {
    setOpenDelete(false);
    let postData = {
      member_id: member_id,
      workout_id: dataDel?._id,
    };
    // console.log(postData, "fdkjahd", dataDel);
    const result = await delete_program_workout_client_Api(postData);
    if (result.code === 200) {
      setEditWorkputData();

      enqueueSnackbar(result.message, { variant: "success" });
      let updatedEvents = events.filter((item) => item._id != dataDel._id);
      setEvents(updatedEvents);
      const today = moment(dataDel?.workout_date).format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format
      const todayCell = document.querySelector(`.fc-day[data-date="${today}"]`); // Find the DOM element corresponding to today's date
      if (todayCell) {
        todayCell.scrollIntoView({ behavior: "instant", block: "start" }); // Scroll the cell into view
      }
      setPastedWorkouts([]);
      setSelectedWorkouts([]);
      setDataCopy(false);
      // reloadList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDraged = async (value) => {
    setOpenDragConfig(false);
    // console.log(DragConfigDate, DragConfigData, "dlkjfkdsjfakslja");
    const formDataObject = {
      title: DragConfigData?.workout_title,
      description: DragConfigData?.description,
      exercises: DragConfigData?.workout_exercises,
      program_id: DragConfigData?.program_info?.program_id,
      member_id: member_id,
      _id: DragConfigData?._id,
      workout_date: moment(DragConfigDate).format("YYYY-MM-DD"),
      video_url: DragConfigData?.video_url,
      video_thumbnail: DragConfigData?.video_thumbnail,
    };
    const formData = new FormData();

    formData.append("title", formDataObject.title);
    formData.append("description", formDataObject.description);

    formData.append("program_id", formDataObject.program_id);
    formData.append("member_id", formDataObject.member_id);
    formData.append("_id", formDataObject._id);
    formData.append("workout_date", formDataObject.workout_date);
    formData.append(
      "video_url",
      formDataObject.video_url ? formDataObject.video_url : ""
    );
    if (formDataObject.video_thumbnail) {
      formData.append(
        "duplicate_image_path",
        JSON.stringify(formDataObject.video_thumbnail)
      );
    }
    formData.append("exercises", JSON.stringify(formDataObject.exercises));
    console.log(formDataObject, "dslfkdjsaa", ...formData);

    const result = await add_edit_program_workout_member_api(formData);
    if (result.code === 200) {
      setEditWorkputData(DragConfigData);

      enqueueSnackbar(result.message, { variant: "success" });
      const workout = result?.workout;
      let newData = {
        ...workout,
        title: workout.title ? workout.title : "",
        color: workout.program_info?.color,
        workout_color: workout.program_info?.color,
        workout_title: workout.title ? workout.title : "",
        workout_exercises: workout.exercises,
        workout_program: workout.program_info,
        is_deleteAble: workout?.workout_has_logs ? false : true,
        workout_completed: workout?.workout_completed,
        status: workout.status,
        open: true,
        date: moment(workout.workout_date).format("YYYY-MM-DD"),
        index: events.length - 1,
        editable: workout?.workout_has_logs ? false : true,
      };
      let list = [...events];
      let indexToUpdate = list.findIndex((item) => item._id === newData?._id);

      if (indexToUpdate !== -1) {
        list[indexToUpdate] = newData;
      }
      setEvents(list);
      // setTimeout(() => {
      // console.log("scrollkdsfkjhsdkjfal");
      const todayCell = document.querySelector(
        `.calendar-workout-card[id="${DragConfigData?._id}"]`
      ); // Find the DOM element corresponding to today's date

      todayCell.scrollIntoView({ behavior: "instant", block: "center" }); // Scroll the cell into view
      // });
      // reloadList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      getEventListing(programWorkoutList, time_zone);
    }
  };
  const handleReorder = async (newdate, newevents) => {
    const ids = newevents.map((event) => event._id);
    let postData = {
      workoutIds: ids,
      member: member_id,
      workout_date: moment(newdate).format("YYYY-MM-DD"),
    };
    console.log(postData, "postData");
    const result = await reorder_member_events_api(postData);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const EventCard = ({ workout, onDelete, onHover }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [data, setData] = useState(workout);
    const timeoutRef = useRef(null);
    const [extra, setExtra] = useState(
      workout._def.extendedProps.workout_exercises
        .map((item) => {
          if (item.is_extra) {
            return item;
          }
          return [];
        })
        .flat()
    );
    useEffect(() => {
      // Cleanup function to clear the timeout on component unmount
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    const formatWithLeadingZeros = (value) => {
      return value < 10 ? `0${value}` : `${value}`;
    };
    const getMinutesForInterval = (mainindex, index) => {
      const list = [...workout._def.extendedProps.workout_exercises];
      const interval = list[mainindex].interval_duration;
      if (interval <= 60) {
        return index + 1;
      }
      if (interval % 60 == 0) {
        return `${
          (interval / 60) * index +
          1 +
          " – " +
          ((interval / 60) * index + interval / 60)
        }`;
      }
      let startInterval = interval * index;
      let endtInterval = interval * index + interval;
      let startintervalText =
        interval * index
          ? Math.floor(startInterval / 60) +
            " " +
            ":" +
            formatWithLeadingZeros(startInterval % 60)
          : 0;
      let endIntervalText =
        Math.floor(endtInterval / 60) +
        " " +
        ":" +
        formatWithLeadingZeros(endtInterval % 60);

      return `${startintervalText + " – " + endIntervalText}`;
    };
    const handleMouseEnter = () => {
      clearTimeout(timeoutRef.current); // Clear any existing timeout
      setIsHovered(true);
    };
    const moveEventUp = (eventId) => {
      // setPastedWorkouts([]);
      // setSelectedWorkouts([]);
      setEditWorkputData(eventId);
      // Find the index of the event in the events array
      const eventIndex = events.findIndex(
        (event) => event.index === eventId.index
      );

      // Check if the event is not the first one on its date and if it exists in the events array
      if (eventIndex > 0 && eventIndex !== -1) {
        // Swap the event with the one above it
        const temp = events[eventIndex];

        events[eventIndex] = events[eventIndex - 1];
        events[eventIndex - 1] = temp;
        // Swap the values of order and index
        [events[eventIndex].order, events[eventIndex - 1].order] = [
          events[eventIndex - 1].order,
          events[eventIndex].order,
        ];
        [events[eventIndex].index, events[eventIndex - 1].index] = [
          events[eventIndex - 1].index,
          events[eventIndex].index,
        ];

        const changedDateEvents = events.filter(
          (event) => event.workout_date === eventId.workout_date
        );
        setEvents([...events]);
        handleReorder(eventId.workout_date, changedDateEvents);

        setTimeout(() => {
          handleScrollCalled(eventId._id);
          // Scroll the cell into view
        }, 0);
      }
    };

    const moveEventDown = (eventId) => {
      // setPastedWorkouts([]);
      // setSelectedWorkouts([]);
      setEditWorkputData(eventId);
      // Find the index of the event in the events array
      const eventIndex = events.findIndex(
        (event) => event.index === eventId.index
      );

      // Check if the event is not the last one on its date and if it exists in the events array
      if (eventIndex < events.length - 1 && eventIndex !== -1) {
        // Swap the event with the one below it
        const temp = events[eventIndex];
        events[eventIndex] = events[eventIndex + 1];
        events[eventIndex + 1] = temp;

        // Swap the values of order and index
        [events[eventIndex].order, events[eventIndex + 1].order] = [
          events[eventIndex + 1].order,
          events[eventIndex].order,
        ];
        [events[eventIndex].index, events[eventIndex + 1].index] = [
          events[eventIndex + 1].index,
          events[eventIndex].index,
        ];

        // Filter the events of the changed date
        const changedDateEvents = events.filter(
          (event) => event.workout_date === eventId.workout_date
        );

        // Call the function to handle reordering on the server/API with only the events of the changed date
        setEvents([...events]);
        handleReorder(eventId.workout_date, changedDateEvents);

        setTimeout(() => {
          handleScrollCalled(eventId._id);
          // Scroll the cell into view
        }, 0);
      }
    };
    const handleMouseLeave = () => {
      // Introduce a delay of 100 milliseconds before updating state on hover out
      const delay = 300;
      timeoutRef.current = setTimeout(() => {
        setIsHovered(false);
      }, delay);
    };
    return (
      <div
        className="calendar-workout-card"
        id={workout._def.extendedProps?._id}
        style={{
          boxShadow: isWorkoutSelected(workout._def.extendedProps)
            ? "-2px -2px 7px 1px #e8ca8a, 1px 1px 7px 2px #e8ca8a"
            : "",
          border:
            editWorkputData?._id == workout._def.extendedProps?._id
              ? "2px solid #daa73c"
              : "0px solid gray",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {is_small_medium_screen() ? (
          <div className="w-100 h-100" onMouseEnter={handleMouseEnter}>
            {
              <div className="all-icon-calendar-workout-general-small-screen d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <Tooltip
                    title={`${
                      workout._def.extendedProps.is_deleteAble
                        ? "Delete"
                        : "Can't be deleted, This workout has results."
                    }`}
                  >
                    <DeleteIcon
                      className={`${
                        workout._def.extendedProps.is_deleteAble
                          ? "del-icon-calendar-workout-general"
                          : "del-icon-calendar-workout-disabled-general"
                      }`}
                      onClick={
                        workout._def.extendedProps.is_deleteAble
                          ? () => handleAgreeDelete(workout._def.extendedProps)
                          : () => {}
                      }
                    />
                  </Tooltip>
                  <Tooltip title={`${"Copy"}`}>
                    <div
                      className={`${"copy-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center"}`}
                    >
                      <ContentCopyIcon
                        sx={{ width: 17, height: 17 }}
                        onClick={() =>
                          handleCopyExercise(workout._def.extendedProps)
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="d-flex">
                  {" "}
                  <Tooltip title="Move Up">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isFirstEventOnDate(
                          workout._def.extendedProps
                        )}
                      >
                        <KeyboardArrowUpIcon
                          sx={{ width: 17, height: 17 }}
                          onClick={() =>
                            moveEventUp(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                  <Tooltip title="Move Down">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isLastEventOnDate(workout._def.extendedProps)}
                      >
                        <KeyboardArrowDownIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventDown(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                </div>
              </div>
            }
          </div>
        ) : (
          <div className="w-100 h-100" onMouseEnter={handleMouseEnter}>
            {isHovered && (
              <div className="all-icon-calendar-workout-general d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <Tooltip
                    title={`${
                      workout._def.extendedProps.is_deleteAble
                        ? "Delete"
                        : "Can't be deleted, This workout has results."
                    }`}
                  >
                    <DeleteIcon
                      className={`${
                        workout._def.extendedProps.is_deleteAble
                          ? "del-icon-calendar-workout-general"
                          : "del-icon-calendar-workout-disabled-general"
                      }`}
                      onClick={
                        workout._def.extendedProps.is_deleteAble
                          ? () => handleAgreeDelete(workout._def.extendedProps)
                          : () => {}
                      }
                    />
                  </Tooltip>
                  <Tooltip title={`${"Copy"}`}>
                    <div
                      className={`${"copy-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center"}`}
                    >
                      <ContentCopyIcon
                        sx={{ width: 17, height: 17 }}
                        onClick={() =>
                          handleCopyExercise(workout._def.extendedProps)
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="d-flex">
                  {" "}
                  <Tooltip title="Move Up">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isFirstEventOnDate(
                          workout._def.extendedProps
                        )}
                      >
                        <KeyboardArrowUpIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventUp(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                  <Tooltip title="Move Down">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isLastEventOnDate(workout._def.extendedProps)}
                      >
                        <KeyboardArrowDownIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventDown(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                </div>
              </div>
            )}
          </div>
        )}

        <div onMouseEnter={handleMouseEnter}>
          <div
            className="calendar-workout-card-title"
            style={{
              backgroundColor: workout._def.extendedProps.workout_color,
            }}
            onMouseEnter={handleMouseEnter}
          >
            <div
              className="calendar-workout-card-program d-flex justify-content-between "
              onMouseEnter={handleMouseEnter}
              onClick={() => handleUpdateIteration(workout._def.extendedProps)}
            >
              <div onMouseEnter={handleMouseEnter}>
                {workout._def.extendedProps?.workout_program?.title}
              </div>
              {workout._def.extendedProps?.workout_completed && (
                <div className="">
                  <Icon
                    icon="fluent-mdl2:completed-solid"
                    className="workout-complete-icon-container "
                  />
                </div>
              )}
            </div>
            <div
              onMouseEnter={handleMouseEnter}
              className="d-flex justify-content-between"
            >
              <div
                className="calendar-workout-card-workout"
                onClick={() =>
                  handleUpdateIteration(workout._def.extendedProps)
                }
                onMouseEnter={handleMouseEnter}
              >
                {workout.title}
              </div>
              <div
                className="d-flex align-items-center"
                onMouseEnter={handleMouseEnter}
              >
                <Icon
                  onClick={() =>
                    handleUpdateIteration(workout._def.extendedProps)
                  }
                  icon={
                    workout._def.extendedProps.practice_type == "workout"
                      ? "mdi:gym"
                      : workout._def.extendedProps.practice_type == "food"
                      ? "fluent:food-24-filled"
                      : workout._def.extendedProps.practice_type == "mindset"
                      ? "flowbite:brain-outline"
                      : workout._def.extendedProps.practice_type == "video"
                      ? "lets-icons:video-fill"
                      : "mdi:gym"
                  }
                  className="workout-type-icon-container"
                />
                {!workout._def.extendedProps?.open ? (
                  <KeyboardArrowDownIcon
                    sx={{
                      cursor: "pointer",
                      opacity: 0.7,
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      const list = [...events];
                      list[workout._def.extendedProps.index]["open"] = true;
                      setEvents(list);
                    }}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    sx={{
                      cursor: "pointer",
                      opacity: 0.7,
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      const list = [...events];
                      list[workout._def.extendedProps.index]["open"] = false;
                      setEvents(list);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {workout._def.extendedProps?.open && (
            <div
              onMouseEnter={handleMouseEnter}
              className="calendar-workout-card-exercises"
            >
              <div
                className="calendar-workout-card-container py-1  "
                onClick={() =>
                  handleUpdateIteration(workout._def.extendedProps)
                }
                onMouseEnter={handleMouseEnter}
              >
                {workout._def.extendedProps.practice_type == "video" ? (
                  <>
                    <div className="inner-div workout-calendar-exercise-card my-1">
                      {workout?._def?.extendedProps?.dumyImage ? (
                        <div
                          style={{
                            whiteSpace: "break-spaces",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            className="image-border"
                            src={workout?._def?.extendedProps?.dumyImage}
                            height="50"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            whiteSpace: "break-spaces",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            className="image-border"
                            src={
                              s3baseUrl +
                              workout?._def?.extendedProps?.video_thumbnail
                                ?.thumbnail_1
                            }
                            height="50"
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {workout._def.extendedProps.workout_exercises.map(
                  (exercise, index) => {
                    if (!exercise.is_extra) {
                      if (exercise?.exercise_type == "something_else") {
                        return (
                          <div
                            className="inner-div workout-calendar-exercise-card my-1 "
                            style={{ position: "relative" }}
                          >
                            {" "}
                            <div
                              style={{
                                whiteSpace: "break-spaces",
                              }}
                              className="py-1"
                            >
                              {exercise?.is_modified && (
                                <Tooltip
                                  title={
                                    exercise?.is_modified
                                      ? "Modified"
                                      : "Not Modified"
                                  }
                                >
                                  <span
                                    style={{
                                      display: "block",
                                      position: "absolute",
                                      top: "-5px",
                                      justifyContent: "center",
                                      zIndex: "1",
                                      right: "0px",
                                    }}
                                  >
                                    <Chip
                                      label={"Modified"}
                                      variant="contained"
                                      color={"error"}
                                      sx={{
                                        fontSize: "6px",
                                        height: "auto",
                                        width: "auto",
                                        borderRadius: "3px",
                                        "& .MuiChip-label": {
                                          paddingLeft: "3px",
                                          paddingRight: "3px",
                                        },
                                      }}
                                    />
                                  </span>
                                  {/* <Icon icon="arcticons:changedetection" /> */}
                                </Tooltip>
                              )}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}

                              {exercise?.title}
                            </div>
                          </div>
                        );
                      } else if (
                        exercise?.exercise_type == "amrap" &&
                        exercise?.emom_amrap &&
                        exercise?.emom_amrap.length > 0
                      ) {
                        return (
                          <div className="workout-calendar-Superset-card inner-div my-1">
                            <div className="text-center">
                              {" "}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}
                              AMRAP
                            </div>
                            {exercise?.emom_amrap?.map(
                              (interval, intervalIndex) => {
                                return (
                                  <div className="my-1">
                                    <div style={{ fontSize: "8px" }}>
                                      Movements
                                    </div>
                                    {interval?.map((exerData) => {
                                      return (
                                        <div
                                          className="inner-div"
                                          style={{
                                            whiteSpace: "break-spaces",
                                            marginBottom: "3px",
                                            // marginBottom: "1px",
                                            // backgroundColor:
                                            //   "rgba(255, 255, 255,255)",
                                            // overflow: "hidden",
                                            // textOverflow: "ellipsis",
                                          }}
                                        >
                                          {exerData?.exercise_info?.title}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      } else if (
                        exercise?.exercise_type == "emom" &&
                        exercise?.emom_amrap &&
                        exercise?.emom_amrap.length > 0
                      ) {
                        return (
                          <div className="workout-calendar-Superset-card inner-div my-1">
                            <div className="text-center">
                              {" "}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}{" "}
                              EMOM
                            </div>
                            {exercise?.emom_amrap?.map(
                              (interval, intervalIndex) => {
                                return (
                                  <div className="my-1">
                                    <div style={{ fontSize: "8px" }}>
                                      Minute{" "}
                                      {getMinutesForInterval(
                                        index,
                                        intervalIndex
                                      )}
                                    </div>
                                    {interval?.map((exerData) => {
                                      return (
                                        <div
                                          className="inner-div"
                                          style={{
                                            whiteSpace: "break-spaces",
                                            marginBottom: "3px",
                                            // marginBottom: "1px",
                                            // backgroundColor:
                                            //   "rgba(255, 255, 255,255)",
                                            // overflow: "hidden",
                                            // textOverflow: "ellipsis",
                                          }}
                                        >
                                          {exerData?.exercise_info?.title}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      } else if (
                        exercise?.sub_exercises &&
                        exercise?.sub_exercises.length > 0
                      ) {
                        return (
                          <div className="workout-calendar-Superset-card inner-div my-1">
                            {exercise?.sub_exercises?.map(
                              (exerData, exerIndex) => {
                                return (
                                  <div
                                    style={{ position: "relative" }}
                                    className="my-2"
                                  >
                                    <div
                                      className="inner-div"
                                      style={{
                                        whiteSpace: "break-spaces",
                                        marginBottom: "3px",
                                      }}
                                      // style={{
                                      //   overflow: "hidden",
                                      //   textOverflow: "ellipsis",
                                      //   fontSize: "11px",
                                      //   fontWeight: 500,
                                      // }}
                                    >
                                      {exerData?.is_modified && (
                                        <Tooltip
                                          title={
                                            exerData?.is_modified
                                              ? "Modified"
                                              : "Not Modified"
                                          }
                                        >
                                          <span
                                            style={{
                                              display: "block",
                                              position: "absolute",
                                              top: "-12px",
                                              justifyContent: "center",
                                              zIndex: "1",
                                              right: "0px",
                                            }}
                                          >
                                            <Chip
                                              label={"Modified"}
                                              variant="contained"
                                              color={"error"}
                                              sx={{
                                                fontSize: "6px",
                                                height: "auto",
                                                width: "auto",
                                                borderRadius: "3px",
                                                "& .MuiChip-label": {
                                                  paddingLeft: "3px",
                                                  paddingRight: "3px",
                                                },
                                              }}
                                            />
                                          </span>
                                          {/* <Icon icon="arcticons:changedetection" /> */}
                                        </Tooltip>
                                      )}
                                      {exerData?.is_completed && (
                                        <Tooltip
                                          title={
                                            exerData?.is_completed
                                              ? "Completed"
                                              : "Not Completed"
                                          }
                                        >
                                          <Icon
                                            icon="fluent-mdl2:completed-solid"
                                            className="calendar-workout-card-complete-exercises-icon"
                                          />
                                        </Tooltip>
                                      )}

                                      {exerData?.exercise_info?.title}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      } else
                        return (
                          <div
                            className="inner-div workout-calendar-exercise-card my-1 "
                            style={{ position: "relative" }}
                          >
                            {" "}
                            <div
                              style={{
                                whiteSpace: "break-spaces",
                              }}
                              className="py-1"
                            >
                              {exercise?.is_modified && (
                                <Tooltip
                                  title={
                                    exercise?.is_modified
                                      ? "Modified"
                                      : "Not Modified"
                                  }
                                >
                                  <span
                                    style={{
                                      display: "block",
                                      position: "absolute",
                                      top: "-5px",
                                      justifyContent: "center",
                                      zIndex: "1",
                                      right: "0px",
                                    }}
                                  >
                                    <Chip
                                      label={"Modified"}
                                      variant="contained"
                                      color={"error"}
                                      sx={{
                                        fontSize: "6px",
                                        height: "auto",
                                        width: "auto",
                                        borderRadius: "3px",
                                        "& .MuiChip-label": {
                                          paddingLeft: "3px",
                                          paddingRight: "3px",
                                        },
                                      }}
                                    />
                                  </span>
                                  {/* <Icon icon="arcticons:changedetection" /> */}
                                </Tooltip>
                              )}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}

                              {exercise?.exercise_info?.title}
                            </div>
                          </div>
                        );
                    }
                  }
                )}
                {extra && extra.length > 0 && (
                  <div>
                    <div className="text-center">
                      <Typography
                        sx={{
                          color: get_root_value("--portal-theme-primary"),
                          fontWeight: "500",
                        }}
                      >
                        <Icon icon="mdi:bicycle-electric" /> Extra Work
                      </Typography>
                      <div
                        style={{
                          whiteSpace: "break-spaces",
                          fontSize: 10,
                          opacity: 0.8,
                        }}
                      >
                        These exercises aren’t part of the original workout
                      </div>
                    </div>

                    <div>
                      {extra.map((exercise, index) => {
                        if (
                          exercise?.sub_exercises &&
                          exercise?.sub_exercises.length > 0
                        ) {
                          return (
                            <div className="workout-calendar-Superset-card inner-div my-1">
                              {exercise?.sub_exercises?.map(
                                (exerData, exerIndex) => {
                                  return (
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {/* {exerData?.is_completed && (
                                        <Icon
                                          icon="fluent-mdl2:completed-solid"
                                          className="calendar-workout-card-complete-exercises-icon mb-1"
                                        />
                                      )} */}
                                      {exerData?.exercise_info?.title}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        } else
                          return (
                            <div className="inner-div workout-calendar-exercise-card my-1">
                              {" "}
                              <div
                                style={{
                                  whiteSpace: "break-spaces",
                                }}
                              >
                                {/* {exercise?.is_completed && (
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon"
                                  />
                                )} */}
                                {exercise?.exercise_info?.title}
                              </div>
                            </div>
                          );
                      })}
                    </div>
                  </div>
                )}
                <div className="mt-2">
                  {workout._def.extendedProps?.comment && (
                    <div
                      style={{
                        whiteSpace: "break-spaces",
                        fontSize: 10,
                        opacity: 0.8,
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 10,
                          opacity: 1,
                          fontWeight: "bold",
                          display: "block",
                          color: get_root_value("--portal-theme-primary"),
                        }}
                      >
                        Comment
                      </span>
                      {workout._def.extendedProps?.comment}
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  {workout._def.extendedProps?.admin_comment && (
                    <div
                      style={{
                        whiteSpace: "break-spaces",
                        fontSize: 10,
                        opacity: 0.8,
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 10,
                          opacity: 1,
                          fontWeight: "bold",
                          display: "block",
                          color: get_root_value("--portal-theme-primary"),
                        }}
                      >
                        Reply
                      </span>
                      {workout._def.extendedProps?.admin_comment}
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="mt-2">
                <div className="d-flex align-items-center">
                  <Icon
                    icon="fluent-mdl2:completed-solid"
                    className="calendar-workout-card-complete-exercises-icon"
                  />
                  <div className="calendar-workout-card-complete-exercises">
                    Completed
                  </div>
                </div>
              </div> */}
            </div>
            // <div
            //   className="calendar-workout-card-container"
            //   onClick={() => handleUpdateIteration(workout._def.extendedProps)}
            // >

            // </div>
          )}
        </div>
      </div>
    );
  };
  const renderEventContent = (eventInfo) => {
    return <EventCard workout={eventInfo.event} onDelete={handleDeleteEvent} />;
  };
  const getEventListing = async (workout, time_zone) => {
    // console.log(workout, "sdfkjlaksdjlkf");
    let all_events = [];
    // moment.tz.setDefault(time_zone);
    if (workout.length > 0) {
      all_events = workout.map((workout, i) => {
        return {
          ...workout,
          title: workout.title ? workout.title : "",
          color: workout.program_info?.color,
          workout_color: workout.program_info?.color,
          workout_title: workout.title ? workout.title : "",
          workout_exercises: workout.exercises,
          workout_program: workout.program_info,
          is_deleteAble: workout?.workout_has_logs ? false : true,
          workout_completed: workout?.workout_completed,
          status: workout.status,
          open: true,
          date: moment(workout.workout_date).format("YYYY-MM-DD"),
          index: i,
          order: i,
          editable: workout?.workout_has_logs ? false : true,
        };
      });
    }
    // console.log(all_events, "=============all_events===============");
    // setEvents(all_events);

    const eventsDict = {};
    all_events.forEach((event) => {
      eventsDict[event._id] = event;
    });

    // List to hold the reordered events
    const reorderedEvents = [];
    let remainingEvents = [...all_events];

    // Reorder events based on reordered_workouts
    reorderedWorkoutList.forEach((workout) => {
      const { workout_date, reorder_workouts } = workout;
      reorder_workouts.forEach((workout_id, index) => {
        if (eventsDict[workout_id]) {
          const event = eventsDict[workout_id];
          event.order = index;
          reorderedEvents.push(event);
          // Remove the event from remainingEvents
          const remainingIndex = remainingEvents.findIndex(
            (e) => e._id === workout_id
          );
          if (remainingIndex > -1) {
            remainingEvents.splice(remainingIndex, 1);
          }
        }
      });

      // Add remaining events of the same date that are not in reorder_workouts
      const remainingSameDateEvents = remainingEvents.filter(
        (event) => event.workout_date === workout_date
      );

      remainingSameDateEvents.forEach((event) => {
        reorderedEvents.push(event);
      });

      // Remove the same date events from remainingEvents
      remainingEvents = remainingEvents.filter(
        (event) => event.workout_date !== workout_date
      );
    });

    // Add any other remaining events
    reorderedEvents.push(...remainingEvents);

    let all_reorder_events = reorderedEvents.map((workout, i) => {
      return {
        ...workout,
        title: workout.title ? workout.title : "",
        color: workout.program_info?.color,
        workout_color: workout.program_info?.color,
        workout_title: workout.title ? workout.title : "",
        workout_exercises: workout.exercises,
        workout_program: workout.program_info,
        is_deleteAble: workout?.workout_has_logs ? false : true,
        workout_completed: workout?.workout_completed,
        status: workout.status,
        open: true,
        date: moment(workout.workout_date).format("YYYY-MM-DD"),
        index: i,
        order: i,
        editable: workout?.workout_has_logs ? false : true,
      };
    });
    setEvents(all_reorder_events);
  };

  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];

  const handleChangeTools = (tool) => {
    let dates = get_dates(tool);
    setCurrentDate(dates);
    setSelectedTool(tool);
  };
  const handleCloseEditIterationDrawer = () => {
    setEditItrationDrawerState(false);
  };
  const getCenteredText = () => {
    let text = moment(new Date()).format("MMM YYYY");
    if (selectedTool == "month") {
      // text = moment(currentDate.start_date).add(1, "month").format("MMM YYYY");

      if (calendarRef?.current) {
        const calendarApi = calendarRef?.current.getApi();
        const currentMonth = calendarApi.getDate().getMonth(); // Get the current month (0-based index)
        const currentYear = calendarApi.getDate().getFullYear(); // Get the current year

        text = moment().month(currentMonth).format("MMM") + " " + currentYear;
      }
      if (
        moment(new Date()).isBetween(
          currentDate.start_date,
          currentDate.end_date,
          null,
          "[)"
        )
      ) {
        // console.log(
        //   moment(new Date()).isBetween(
        //     currentDate.start_date,
        //     currentDate.end_date,
        //     null,
        //     "[)"
        //   ),
        //   "dsfjldsjfkasdjfka"
        // );
      }
    } else if (selectedTool == "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;

    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();

      const currentView = calendarApi.view;
      const startDate = currentView.activeStart;
      const endDate = currentView.activeEnd;
      start_date = startDate;
      end_date = endDate;
      console.log("Start date of the current month:", startDate, endDate);
    }
    let new_object = {};
    if (value == "next") {
      new_object = {
        start_date: moment(start_date),
        end_date: moment(end_date),
      };
    } else {
      new_object = {
        start_date: moment(start_date),
        end_date: moment(end_date),
      };
    }
    // console.log(new_object, "djflksdklfla");
    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    if (selectedTool == "month") {
      let classToClick = "fc-next-button";
      if (value == "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }
    hanldeChangeDates(value);
  };

  const handleClickDate = (workout) => {
    setSelectedTool("day");
    setCurrentDate({
      start_date: workout.dateStr,
      end_date: workout.dateStr,
    });
  };
  const handleEventDrop = (info) => {
    const { event, oldEvent, view } = info;

    // Access the dropped date using the view's activeStart property
    const dropDate = view.calendar.formatDate(event.start, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    // Access the dropped event's data
    const eventData = event.extendedProps; // assuming you have extendedProps with data
    setOpenDragConfig(true);
    setDragConfigDate(event.start);
    setDragConfigData(eventData);
    // console.log("Dropped Date:", dropDate, view.calendar, event.start);
    // console.log("Dropped Event Data:", eventData);
  };

  const renderDayCell = (arg) => {
    // Render custom content for each date cell

    const isHovered =
      hoveredDate && arg.date.getTime() === hoveredDate.getTime();
    //  const { dayNumberText, day } = eventInfo;
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          marginTop: -10,
        }}
      >
        <span>{arg?.dayNumberText}</span>{" "}
        <span
          className="day-cell-name"
          style={{
            opacity: 0.5,
            fontSize: 10,
          }}
        >
          {moment(arg?.date).format("ddd")}
        </span>
        {isHovered && (
          <div className="cell-icon-workout d-flex text-end w-100">
            {dataCopy && (
              <Tooltip touch="on" placement="bottom" title={"Paste"}>
                <div className="paste-icon-workout-general me-1">
                  <AssignmentTurnedInIcon
                    onClick={() => handlePaste(arg?.date)}
                    sx={{
                      cursor: "pointer",
                      height: 15,
                      width: 15,
                      mb: "2px",
                      mr: "2px",
                    }}
                  />
                </div>
              </Tooltip>
            )}

            <AddIcon
              className="add-icon-workout-general "
              onClick={() => handleOpenDrawer(arg?.date)}
              sx={{
                cursor: "pointer",
              }}
            />
          </div>
        )}
        {/* <AddIcon className="add-icon-workout" /> */}
      </div>
    );
  };
  const goToToday = () => {
    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();
      const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
      const todayCell = document.querySelector(`.fc-day[data-date="${today}"]`); // Find the DOM element corresponding to today's date
      if (todayCell) {
        todayCell.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll the cell into view
      }
    }
  };
  useEffect(() => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;

    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();

      const currentView = calendarApi.view;
      const startDate = currentView.activeStart;
      const endDate = currentView.activeEnd;
      start_date = startDate;
      end_date = endDate;
    }
  }, [calendarRef]);

  function getNewDates(refDate, inputDate) {
    const dateDifferences = [];
    const referenceDates = refDate
      .map((date) => new Date(date.split("/").reverse().join("-")))
      .sort((a, b) => a - b);

    for (let i = 1; i < referenceDates.length; i++) {
      const diffInDays =
        (referenceDates[i] - referenceDates[i - 1]) / (1000 * 60 * 60 * 24);
      dateDifferences.push(diffInDays);
    }

    const newDates = [new Date(inputDate.split("/").reverse().join("-"))];

    for (let diff of dateDifferences) {
      const nextDate = new Date(newDates[newDates.length - 1]);
      nextDate.setDate(nextDate.getDate() + diff);
      newDates.push(nextDate);
    }

    newDates.sort((a, b) => a - b);

    return newDates.map((date) =>
      date.toLocaleDateString("en-GB").split("/").join("/")
    );
  }

  useEffect(() => {
    if (!hoverdInfo) return;

    const cellDate = moment(hoverdInfo?.date).format("DD/MM/YYYY");

    const dates = selectedWorkouts.map((workout) => {
      return moment(workout.workout_date).format("DD/MM/YYYY");
    });

    let newDates = [];
    if (dates.length > 0 && cellDate) {
      newDates = getNewDates(dates, cellDate);
    }

    document.querySelectorAll(".fc-daygrid-day").forEach((cell) => {
      cell.classList.remove("highlighted-hover");
    });

    if (newDates.length > 0) {
      document.querySelectorAll(".fc-daygrid-day").forEach((cell) => {
        const cellDate = moment(cell.getAttribute("data-date")).format(
          "DD/MM/YYYY"
        );

        if (newDates.includes(cellDate)) {
          cell.classList.add("highlighted-hover");
        }
      });
    }
  }, [hoverdInfo, selectedWorkouts]);

  useEffect(() => {
    let timeout;
    if (!isLoading && calendarRef?.current) {
      timeout = setTimeout(() => {
        const calendarApi = calendarRef?.current.getApi();
        const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
        const todayCell = document.querySelector(
          `.fc-day[data-date="${today}"]`
        ); // Find the DOM element corresponding to today's date
        if (todayCell) {
          todayCell.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll the cell into view
        }
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [isLoading]);

  useEffect(() => {
    getEventListing(programWorkoutList, time_zone);
  }, [programWorkoutList]);
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setDataCopy(""); // Clear the data
  //   }, 15000);

  //   return () => clearTimeout(timeoutId);
  // }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrolled = window.scrollY;
  //     console.log("Page scrolled:", scrolled, "pixels");
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // useEffect(() => {
  //   if (editWorkputData?._id) {
  //     // Get today's date in YYYY-MM-DD format
  //     setTimeout(() => {
  //       console.log("scrollkdsfkjhsdkjfal");
  //       const todayCell = document.querySelector(
  //         `.calendar-workout-card[id="${editWorkputData?._id}"]`
  //       ); // Find the DOM element corresponding to today's date

  //       todayCell.scrollIntoView({ behavior: "instant", block: "center" }); // Scroll the cell into view
  //     }, 0); // Delay by 1 second (1000 milliseconds)
  //   }
  // }, [events, editWorkputData]);

  //  useEffect(() => {
  //   if (
  //     editWorkputData?._id == workout._def.extendedProps?._id &&
  //     calendarRef?.current
  //   ) {
  //     // Get today's date in YYYY-MM-DD format
  //     const todayCell = document.querySelector(
  //       `.calendar-workout-card[id="${editWorkputData?._id}"]`
  //     ); // Find the DOM element corresponding to today's date
  //     if (todayCell) {
  //       todayCell.scrollIntoView({ behavior: "instant", block: "center" }); // Scroll the cell into view
  //     }
  //   }
  // }, [events, editWorkputData]);
  const eventOrderFunction = (a, b) => {
    // console.log(a, b, "a and  b");
    return a.order - b.order;
  };
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12 text-center text-md-start text-lg-start">
          <h2 className="font-size-on-sm">{calendar_title}</h2>
        </div>
        <div style={{ justifyContent: "end", display: "flex" }}>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Notepad
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            disableEnforceFocus={true}
            maxWidth={false} // Disable maxWidth to allow full control over width
            fullWidth
            sx={{
              "& .MuiDialog-paper": {
                width: "50vw",
                maxWidth: "none",
                height: "90vh",
              },
            }}
          >
            <div className="close_custom_icon" onClick={handleClose}>
              <CloseIcon
                style={{
                  fill: "var(--portal-theme-primary)",
                  fontSize: 18,
                  marginTop: 6,
                }}
              />
            </div>
            <DialogTitle sx={{ textAlign: "center" }}>
              Coach note for{" "}
              {type_member
                ? programme.title
                : programme.first_name + " " + programme.last_name}
            </DialogTitle>
            <DialogContent
              sx={{
                height: "calc(100% - 64px)", // Adjust height to fill dialog (64px for title & actions)
                overflowY: "auto",
              }}
            >
              <div className="editor-container">
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  name="description"
                  editorHeight={450}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleAddNote}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div className="container-fluid">
          <div className="row">
            {/* Calendar Section - Left Side */}
            <div className="col-lg-12 col-md-12 full-calendar mt-3 order-1 order-lg-1">
              {selectedTool === "month" ? (
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: "today prev,next",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  className="full-calendar-class"
                  eventContent={renderEventContent}
                  events={events}
                  initialView="dayGridMonth"
                  dayCellContent={renderDayCell}
                  editable
                  selectable
                  selectMirror
                  eventDrop={handleEventDrop}
                  eventOrder={eventOrderFunction}
                  weekends
                  fixedWeekCount={false}
                  moreLinkContent={(args) => "+" + args.num}
                  moreLinkClick={handleClickDate}
                  dayCellDidMount={(arg) => {
                    const cell = arg.el;
                    cell.addEventListener("mouseover", () =>
                      handleCellHover(arg)
                    );
                    cell.addEventListener("mouseout", () =>
                      handleCellUnhover(arg)
                    );
                  }}
                />
              ) : selectedTool === "week" ? (
                <WeekCalendar
                  events={events}
                  handleClick={eventDetail}
                  currentDate={currentDate}
                  isLoading={isLoading}
                />
              ) : (
                <DayCalendar
                  events={events}
                  handleClick={eventDetail}
                  currentDate={currentDate}
                  isLoading={isLoading}
                />
              )}
            </div>

            {/* CKEditor Section - Right Side */}
          </div>
        </div>
      </div>
      <DetailPopUpModel
        open={popupState}
        setOpen={setPopupState}
        handleAgreeDelete={handleAgreeDelete}
        handleEdit={handleUpdate}
        handleEditIteration={handleUpdateIteration}
        title={"Event Detail"}
        eventDetailData={eventDetailData}
        componentToPassDown={<></>}
      />

      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to Delete?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        openDelete={openDragConfig}
        setOpenDelete={closeDragConfig}
        title={"Are you sure you want to Move it?"}
        handleAgree={handleDraged}
      />
      <FullPagePopup
        open={editDrawerState}
        setOpen={setEditDrawerState}
        componentToPassDown={<></>}
      />

      <AddWorkoutPopupClient
        open={drawerState}
        setOpen={setDrawerState}
        date={selectedDate}
        programme={programme}
        member_id={member_id}
        reloadList={reloadList}
        member_vdot={member_vdot}
        setEditWorkputData={setEditWorkputData}
        setEvents={setEvents}
        events={events}
      />
      <EditWorkoutPopupClient
        open={editItrationDrawerState}
        setOpen={setEditItrationDrawerState}
        date={selectedDate}
        programme={programme}
        member_id={member_id}
        data={editWorkputData}
        reloadList={reloadList}
        member_vdot={member_vdot}
        setEvents={setEvents}
        events={events}
      />
    </div>
  );
}

export default GeneralCalendarClient;
