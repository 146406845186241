import { invokeApi } from "../../bl_libs/invokeApi";

export const ProgramListing = async (data) => {
  const requestObj = {
    path: `api/program`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
