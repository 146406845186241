import { invokeApi } from "../../bl_libs/invokeApi";

export const groupsListing = async (page, limit, search) => {
  const requestObj = {
    path: `api/community_group?search=${search}&limit=${limit}&page=${page}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const groupsListingFeeds = async () => {
  const requestObj = {
    path: `api/community_group/list_community_groups/for_user`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const communityGroupDetail = async (slug) => {
  const requestObj = {
    path: `api/community_group/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddGroupApi = async (data) => {
  const requestObj = {
    path: `api/community_group`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditCommunityGroupApi = async (data, slug) => {
  const requestObj = {
    path: `api/community_group/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteCommunityGroup = async (slug) => {
  const requestObj = {
    path: `api/community_group/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const membersToAddInCommunityGroup = async (slug, search) => {
  const requestObj = {
    path: `api/community_group/member_list/to_add_to_community_group/${slug}?search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddMemberstoGroupApi = async (data, slug) => {
  const requestObj = {
    path: `api/community_group/add/members_to_community_group/${slug}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const membersAddedInCommunityGroup = async (slug) => {
  const requestObj = {
    path: `api/community_group/members_list/in_community_group/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddMembersFromProgramApi = async (data, slug) => {
  const requestObj = {
    path: `api/community_group/add/program_members_to_community_group/${slug}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteCommunityGroupMember = async (data, slug) => {
  const requestObj = {
    path: `api/community_group/remove_members/from_community_group/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const groupDetail = async (slug) => {
  const requestObj = {
    path: `api/community_group/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
