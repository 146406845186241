import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { get_root_value } from "src/utils/domUtils";
import { memberListing } from "src/DAL/member/Member1";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  programmeActiveListing,
  programmeDetail,
} from "src/DAL/Programme/Programme";
import { AddWorkoutAgainstMemberApi } from "src/DAL/workout/workout";
import { useSnackbar } from "notistack";
function WorkoutAssignProgramModel({ open, setOpen, data }) {
  const [personName, setPersonName] = React.useState([]);
  const [serchText, setSerchText] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const [programmeDetailInfo, setProgrammeDetailInfo] = useState("");
  const [inputs, setInputs] = useState({
    member: null,
    date: null,
    programs: [],
    programs_id: null,
    day: "",
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const getPrograms = async () => {
    let result = await programmeActiveListing();
    if (result.code === 200) {
      setPersonName(result?.program);
      setInputs((values) => ({
        ...values,
        programs: result?.program,
        programs_id: null,
      }));
    } else {
      // enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleDetail = async (id) => {
    let result = await programmeDetail(id);
    if (result.code === 200) {
      console.log(result, "okokok");
      setProgrammeDetailInfo(result.program);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSaveExistingWorkout = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    if (!inputs.programs_id) {
      setIsDisabled(false);

      enqueueSnackbar("please select Program", { variant: "error" });
      return;
    }
    if (
      programmeDetailInfo?.program_length_type == "unlimited" &&
      !inputs.date
    ) {
      setIsDisabled(false);

      enqueueSnackbar("please Enter Date", { variant: "error" });
      return;
    }
    if (!programmeDetailInfo) {
      setIsDisabled(false);
      return;
    }
    if (
      !programmeDetailInfo?.program_length_type == "unlimited" &&
      !inputs.day
    ) {
      setIsDisabled(false);

      enqueueSnackbar("please Enter Day", { variant: "error" });
      return;
    }
    const formDataObject = {
      workout_id: data?._id,
      program_id: inputs?.programs_id?._id,
      workout_day: inputs?.day,
      workout_date: moment(inputs.date).format("YYYY-MM-DD"),
      member_id: inputs?.member?._id,
      type: "program",
      // practice_type: "mindset",
    };
    console.log(formDataObject, "formDataObject");

    const result = await AddWorkoutAgainstMemberApi(formDataObject);
    if (result.code === 200) {
      setIsDisabled(false);
      setOpen(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsDisabled(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  React.useEffect(() => {
    if (inputs.programs_id) {
      setProgrammeDetailInfo(inputs?.programs_id);
      setIsDisabled(false);
    } else {
      setProgrammeDetailInfo("");
    }
  }, [inputs.programs_id]);

  React.useEffect(() => {
    if (open) {
      setInputs({
        member: null,
        date: null,
        programs: [],
        programs_id: null,
        day: "",
      });
      setProgrammeDetailInfo("");
      getPrograms();
      setIsLoading(false);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth={"sm"}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <div
          className={`text-end mt-1 d-flex justify-content-between align-items-center`}
        >
          <DialogTitle
            sx={{
              paddingY: 0,
              color: get_root_value("--portal-theme-primary"),
            }}
          >
            Add to Program
          </DialogTitle>

          <IconButton
            className="back-screen-button me-1 "
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <FormControl fullWidth className="mt-3">
              <Autocomplete
                id="tags-outlined"
                options={inputs.programs}
                clearOnBlur={true}
                getOptionLabel={(option) => option?.title}
                filterSelectedOptions
                value={inputs?.programs_id}
                onChange={(event, newValue) => {
                  // setMember(newValue);
                  setInputs((values) => ({
                    ...values,
                    programs_id: newValue,
                  }));
                }}
                className="mui-autocomplete"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Programs"
                    placeholder="Programs"
                    onChange={handleSearch}
                  />
                )}
              />
            </FormControl>
            {programmeDetailInfo ? (
              programmeDetailInfo?.program_length_type == "unlimited" ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date"
                    name="date"
                    value={inputs.date}
                    format="YYYY-MM-DD"
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) =>
                      setInputs((values) => ({
                        ...values,
                        date: e,
                      }))
                    }
                    PopperProps={{
                      placement: "top-end",
                      style: {
                        height: 100,
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="mt-3 inputs-fields w-100"
                        required={true}
                      />
                    )}
                  />
                </LocalizationProvider>
              ) : (
                <TextField
                  id="outlined-basic"
                  label={`No Of Day (1 to ${
                    programmeDetailInfo?.no_of_weeks * 7
                  })`}
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  placeholder={`No Of Day (1 to ${
                    programmeDetailInfo?.no_of_weeks * 7
                  })`}
                  fullWidth
                  required
                  name="day"
                  value={inputs.day}
                  onChange={(e) => {
                    setInputs((values) => ({
                      ...values,
                      day: e.target.value,
                    }));
                  }}
                  className="mt-3  w-100"
                />
              )
            ) : (
              ""
            )}
          </DialogContentText>
          <div className="text-end mt-4">
            <button
              className="small-contained-button"
              onClick={handleSaveExistingWorkout}
              disabled={isDisabled}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default WorkoutAssignProgramModel;
