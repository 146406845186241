import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Box,
  CircularProgress,
  Chip,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactPlayer from "react-player";
import RatingCard from "../../components/_dashboard/programmes/RatingCard";
import ProgrammRecording from "../../components/_dashboard/programmes/ProgrammeRecording";
import ResourcesCard from "../../components/_dashboard/programmes/ResourcesCard";
import { s3baseUrl } from "src/config/config";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import MenuOption from "src/components/MenuOption/MenuOptions";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";

import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import { htmlDecode } from "src/utils/convertHtml";
import {
  deleteExerciseApi,
  exercise_detail_api,
} from "src/DAL/exercise/exercise";

const ITEM_HEIGHT = 48;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ExerciseDetail(props) {
  const id = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lessonDetailInfo, setLessonsDetailInfo] = useState();
  const [exerciseTags, setExerciseTags] = useState([]);
  const [difficultyTags, setDifficultyTags] = useState([]);
  const [mainMuscleTags, setMainMuscleTags] = useState([]);
  // let programName = lessonDetailInfo?.program[0]?._id?.title;
  // let program_slug_navgation = lessonDetailInfo?.program[0]?._id?.program_slug;
  // console.log(program_slug_navgation, "dsfkhsdkjfha");
  // let breadCrumbMenu = [
  //   {
  //     title: "Programmes",
  //     navigation: `/programmes`,
  //     active: false,
  //   },
  //   {
  //     title: programName,
  //     navigation: `/programmes/programmes-detail/${program_slug_navgation}`,
  //     active: false,
  //   },
  //   {
  //     title: "Lessons",
  //     navigation: `/programmes/lessons-listing/${program_slug_navgation}`,
  //     active: false,
  //   },
  //   {
  //     title: lessonDetailInfo?.title,
  //     navigation: null,
  //     active: true,
  //   },
  //   // {
  //   //   title: "Lesson Documents",
  //   //   navigation: ``,
  //   //   active: true,
  //   // },
  // ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = async (value) => {
    //console.log(id, "delete it ");
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteExerciseApi(id.slug);
    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleNavigateEdit = (value) => {
    //console.log(lessonDetailInfo, "edit value");
    navigate(`/exercise-edit/${lessonDetailInfo.exercise_slug}`, {
      state: lessonDetailInfo,
    });
  };
  const handleNavigateRecordings = (value) => {
    //console.log(lessonDetailInfo, "edit value");
    navigate(
      `/programmes/lessons/recordings/${lessonDetailInfo.exercise_slug}`
    );
  };
  const handleNavigateManageDoc = (value) => {
    //console.log(lessonDetailInfo, "edit value");
    //console.log(lessonDetailInfo.exercise_slug, "edit slug");
    navigate(`/programmes/lesson/lesson-doc/${lessonDetailInfo.exercise_slug}`);
  };
  const handleDetail = async () => {
    setIsLoading(true);
    let result = await exercise_detail_api(id.slug);
    if (result.code === 200) {
      // setlessonName(result.lesson.title);
      console.log(result, lessonDetailInfo, "dfaksdkjfhakdsjhfkjads");
      setLessonsDetailInfo(result?.exercise);
      setExerciseTags(result?.exercise?.tags?.exercise);
      setDifficultyTags(result?.exercise?.tags?.difficulty);
      setMainMuscleTags(result?.exercise?.tags?.main_muscle);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    handleDetail();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(lessonDetailInfo, "dfaksdkjfhakdsjhfkjads");
  console.log(id, "idididid", location);
  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete Exercise?"}
        handleAgree={handleDelete}
      />
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} /> */}

          {/* <button
            className="small-contained-button float-end mt-1"
            // onClick={() => navigate(`/programmes/lessons_card/${id}`, { state: lessonDetailInfo })}
          >
            Mark Complete
          </button> */}
        </div>
      </div>

      <div className="row section-space">
        <div className="col-9">
          <h1 className="programmes-heading">{lessonDetailInfo?.title}</h1>
        </div>
        {lessonDetailInfo?.action_by === "coach_user" && (
          <div className="col-3 text-end">
            {/* <MenuOption /> */}
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 6.5,
                  width: "30ch",
                  fontSize: "12px",
                },
              }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              {/* <MenuItem onClick={handleNavigateManageDoc}>
              <SubjectIcon className="me-3" height="10px" /> Manage Resources
            </MenuItem>{" "}
            <MenuItem onClick={handleNavigateRecordings}>
              <VideoLibraryIcon className="me-3" height="10px" /> Manage
              Recording
            </MenuItem>{" "} */}
              <MenuItem onClick={handleNavigateEdit}>
                <EditIcon className="me-3" height="10px" /> Edit
              </MenuItem>
              <MenuItem onClick={handleClickOpenDelete}>
                <DeleteIcon className="me-3" height="10px" />
                Delete
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
      <div className="row media-margin">
        <div className="col-12 ">
          {/* <ReactPlayer
            controls
            playIcon
            url="https://vimeo.com/226053498"
            width="100%"
            className="custom-video-player"
            pip
            stopOnUnmount
            playsinline
            volume={0}
            muted={0}
          /> */}
          {/* <video width="100%" controls>
            <source src="https://vimeo.com/226053498" type="video/mp4" />
            <track
              src="captions_en.vtt"
              kind="captions"
              srcLang="en"
              label="english_captions"
            />
          </video> */}
          {/* <img
            width="100%"
            src="https://images.pexels.com/photos/4473622/pexels-photo-4473622.jpeg?cs=srgb&dl=pexels-ketut-subiyanto-4473622.jpg&fm=jpg"

            // src={s3baseUrl + lessonDetailInfo?.lesson_images.thumbnail_1}
          /> */}
          {/* =====orignal code */}
          {lessonDetailInfo?.video_url == "" ? (
            lessonDetailInfo?.gif_url ? (
              <div className="w-100 d-flex justify-content-center">
                <img
                  // width="100%"
                  // src="https://images.pexels.com/photos/4473622/pexels-photo-4473622.jpeg?cs=srgb&dl=pexels-ketut-subiyanto-4473622.jpg&fm=jpg"

                  src={s3baseUrl + lessonDetailInfo?.gif_url}
                />
              </div>
            ) : (
              <img
                width="100%"
                // src="https://images.pexels.com/photos/4473622/pexels-photo-4473622.jpeg?cs=srgb&dl=pexels-ketut-subiyanto-4473622.jpg&fm=jpg"

                src={s3baseUrl + lessonDetailInfo?.image?.thumbnail_1}
              />
            )
          ) : (
            <ReactVideoPlayer url={lessonDetailInfo?.video_url} />
          )}
        </div>
        {/* <div className="col-12 section-space">
          <button
            className="small-contained-button"
            // onClick={() => navigate(`/programmes/lessons_card/${id}`, { state: lessonDetailInfo })}
          >
            {"<<"} Previous
          </button>
          <button
            className="small-contained-button float-end"
            // onClick={() => navigate(`/programmes/lessons_card/${id}`, { state: lessonDetailInfo })}
          >
            Next >>
          </button>
        </div> */}

        <div className="col-6 section-space">
          <div
            className="d-flex"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            <div className="me-2">Type:</div>
            <div style={{ opacity: "0.6" }}>
              {htmlDecode(location?.state?.title)}
            </div>
          </div>
        </div>
        {(exerciseTags.length > 0 ||
          mainMuscleTags.length > 0 ||
          difficultyTags.length > 0) && (
          <div className="col-12 mt-3 d-flex align-items-start">
            <div className="mt-1"> Tags:</div>
            <Box sx={{ marginTop: 0 }}>
              {exerciseTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  // onDelete={handleDelete(tag)}
                  sx={{ marginX: 0.5, marginY: 0.5 }}
                />
              ))}
              {mainMuscleTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  // onDelete={handleDelete(tag)}
                  sx={{ marginX: 0.5, marginY: 0.5 }}
                />
              ))}
              {difficultyTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  // onDelete={handleDelete(tag)}
                  sx={{ marginX: 0.5, marginY: 0.5 }}
                />
              ))}
            </Box>
          </div>
        )}
        <div className="col-12 section-space">
          <p>
            <div
              dangerouslySetInnerHTML={{
                // __html: lessonDetailInfo?.detailed_description,
                __html: location?.state?.instructions,
              }}
            ></div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ExerciseDetail;
