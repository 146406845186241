import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Tooltip,
  FormHelperText,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { ExerciseListingApi } from "src/DAL/exercise/exercise";

import faker from "faker";

import ToshowExercise from "./component/modalToshowExercise";

import moment from "moment";
import {
  add_edit_program_workout_member_api,
  add_edit_program_workout_Reply_member_api,
  update_program_workout_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import WorkoutExercisesUI from "../Workout/WorkoutExercisesUI";
import WorkoutExercisesUIClient from "./WorkoutExercisesUIClient";
import { Icon } from "@iconify/react";
import WorkoutExercisesUILogedClient from "./WorkoutExercisesUILogedClient";
import { get_root_value } from "src/utils/domUtils";
import {
  handleScrollCalled,
  is_LG_screen,
  paceFromVdot,
} from "src/utils/constant";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { convertToTitleCase } from "src/utils/convertHtml";
import FoodMattersAndMindsetUI from "../FoodMattersLibrary/FoodMattersAndMindsetUI";
import AnswerdFoodMattersAndMindsetUI from "./AnswerdFoodMattersAndMindsetUI";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import WorkoutExercisesHistoryUI from "../Workout/WorkoutExercisesHistoryUI";
import RecordNotFound from "src/components/RecordNotFound";
import { s3baseUrl } from "src/config/config";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const finalSpaceCharacters = [
  {
    id: "gary",
    name: "Gary Goodspeed",
    thumb: "/images/gary.png",
  },
  {
    id: "cato",
    name: "Little Cato",
    thumb: "/images/cato.png",
  },
  {
    id: "kvn",
    name: "KVN",
    thumb: "/images/kvn.png",
  },
  {
    id: "mooncake",
    name: "Mooncake",
    thumb: "/images/mooncake.png",
  },
  {
    id: "quinn",
    name: "Quinn Ergon",
    thumb: "/images/quinn.png",
  },
];
const findPaceObjectByVdot = (vdotValue) => {
  return paceFromVdot.find((paceObject) => paceObject.vdot == vdotValue);
};
const AddWorkoutCalenderClient = forwardRef((props, ref) => {
  const vdot33Object = findPaceObjectByVdot(
    props?.member_vdot ? props?.member_vdot : 30
  );
  const {
    userExerciseHistory,
    setUserExerciseHistory,
    userExerciseHistoryLoading,
  } = useContentSetting();

  const [practiceType, setPracticeType] = useState(props.practice_type);

  const { previousRoute, setPreviousRouteHandler } = usePGIMode();

  // console.log(addArray, setaddArray, "dflkadsjjkfahds");
  const [characters, updateCharacters] = useState(finalSpaceCharacters);
  const { state, pathname } = useLocation();
  const myInputRef = useRef(null);
  const [serchText, setSerchText] = useState("");
  const [comment, setCommnet] = useState("");
  const [adminComment, setAdminCommnet] = useState("");
  const [file, setImage] = React.useState();
  const [imageStatus, setImageStatus] = useState(false);
  const [oldImage, setOldImage] = useState();
  const [inputs, setInputs] = useState({
    video_url: "",
    image: "",
  });
  const [addArray, setaddArray] = useState([
    {
      exercise: "",

      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
          vdot: props?.member_vdot ? props?.member_vdot : null,
          race_type: "c",
          pace_type: "c_km",
          pace: props?.member_vdot
            ? vdot33Object.c_km
              ? vdot33Object.c_km
              : 0
            : 0,
          paceUp: 423,
          optionsType: [
            { title: "1 KM Pace", key: "c_km" },
            { title: "1 MILE Pace", key: "c_mi" },
          ],
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: practiceType == "workout" ? "general" : "something_else",
      description: "",

      superSet: [],
      emomSets: [[]],
      superset_sets: "",
    },
  ]);
  const [addArrayExtra, setaddArrayExtra] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [exercise, setExercise] = useState([]);
  const [workoutTitle, setWorkoutTitle] = useState("");

  const [detailsData, setDetailsData] = useState("");
  const [openDetails, setOpenDetails] = useState(false);

  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [workoutDescription, setWorkoutDescription] = useState("");
  const isInitialRender = useRef(true);

  const handleAdd = () => {
    let list = addArray;
    list.splice(addArray.length, 0, {
      exercise: "",

      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
          vdot: props?.member_vdot ? props?.member_vdot : null,
          race_type: "c",
          pace_type: "c_km",
          pace: props?.member_vdot ? vdot33Object.c_km : 0,
          paceUp: 423,
          optionsType: [
            { title: "1 KM Pace", key: "c_km" },
            { title: "1 MILE Pace", key: "c_mi" },
          ],
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "general",
      description: "",

      superSet: [],
      emomSets: [[]],
      superset_sets: "",
    });

    setaddArray([...list]);
    props.setIsLoading(false);
  };
  useImperativeHandle(ref, () => ({
    childButtonClick() {
      handleSubmit();
      console.log("Button clicked in child component");
      // Add your logic or call other functions here
    },
    childButtonClick2() {
      console.log("Button2 clicked in child component");

      handleSubmit().then((formDataObject) => {
        if (formDataObject) props.setWorkoutAsTem(formDataObject);
      });
    },
    childButtonClickUpdate() {
      console.log("Button Update clicked in child component");
      handleSubmitUpdate();
      // props?.editData?.workout_has_logs
      //   ? handleSubmitJustComment()
      //   : handleSubmitUpdate();
    },
  }));

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlur1 = () => {
    setIsFocused1(false);
  };

  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };

  // console.log(level, "levellevellevel");

  function handleOnDragEnd(result) {
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setaddArray(items);
  }
  const fileChangedHandler = (e) => {
    setImageStatus(true);
    setImage(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files[0], "sdalkfjlkdsjfa");
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };
  const handleSubmit = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    props.setIsLoading(true);
    let isError = false;
    if (practiceType == "video") {
      if (inputs.video_url) {
        if (
          urlPatternValidation(inputs.video_url) === false &&
          inputs.video_url != ""
        ) {
          enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
          isError = true;
          setIsLoading(false);
          props.setIsLoading(false);
          return;
        }
      } else {
        enqueueSnackbar("Please Enter Video URL", {
          variant: "error",
        });
        isError = true;
        setIsLoading(false);
        props.setIsLoading(false);
        return;
      }
    }
    if (inputs.video_url) {
      if (
        urlPatternValidation(inputs.video_url) === false &&
        inputs.video_url != ""
      ) {
        enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
        isError = true;
        setIsLoading(false);
        props.setIsLoading(false);
        return;
      }
    }

    if (inputs.video_url == "" && (inputs.image || oldImage)) {
      enqueueSnackbar("Please Enter Video URL", {
        variant: "error",
      });
      isError = true;
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }

    if (inputs.video_url && !inputs.image && !oldImage) {
      enqueueSnackbar("Please Upload Video Thumbnail", { variant: "error" });
      isError = true;
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }
    let exerciesArray =
      practiceType == "video"
        ? []
        : addArray.map((item, mainIn) => {
            console.log(item, "sdkfljkajs");
            if (
              item.type != "emom" &&
              item.type != "amrap" &&
              item.type != "something_else" &&
              !item.exercise?._id &&
              item.superSet.length == 0
            ) {
              enqueueSnackbar("please select an exercise", {
                variant: "error",
              });
              isError = true;
            }
            if (item.type == "something_else" && !item?.title) {
              enqueueSnackbar("please add title", {
                variant: "error",
              });
              isError = true;
            }
            let data = {
              exercise_info: {
                _id: item?.superSet[0]?.exercise?._id
                  ? item?.superSet[0]?.exercise?._id
                  : item.exercise?._id,
                title: item?.superSet[0]?.exercise?.title
                  ? item?.superSet[0]?.exercise?.title
                  : item.exercise?.title,
                image: item?.superSet[0]?.exercise?.image
                  ? item?.superSet[0]?.exercise?.image
                  : item.exercise?.image,
              },
              title: item?.title,
              description: item.description,
              parameters: item.parameters,
            };
            if (item?._id) {
              data["_id"] = item?._id;
            }
            if (item.superset_sets) {
              data["superset_sets"] = item.superset_sets;
            }
            if (item.rounds) {
              data["emom_rounds"] = item.rounds;
            }
            if (item.type) {
              data["exercise_type"] = item.type;
            }
            if (item.time_interval) {
              data["interval_duration"] = item.time_interval;
            }
            if (item.set) {
              data["sets"] = [];
              if (item.set.length > 0) {
                data["sets"] = item.set.map((item) => {
                  console.log("dskjfjksjdklfja", item);
                  let data = {};
                  if (item.vdot) {
                    data["vdot"] = item.vdot;
                  }
                  if (item.race_type) {
                    data["race_type"] = item.race_type;
                  }
                  if (item.pace_type) {
                    data["pace_type"] = item.pace_type;
                  }
                  if (item.pace) {
                    data["pace_low"] = item.pace;
                  }
                  if (item.paceUp) {
                    data["pace_up"] = item.paceUp;
                  }
                  if (item.optionsType) {
                    data["pace_options"] = item.optionsType;
                  }
                  if (item.mhr) {
                    data["mhr"] = item.mhr;
                  }
                  if (item.reps) {
                    data["reps"] = item.reps;
                  }
                  if (item.rest) {
                    data["rest"] = item.rest;
                  }
                  if (item.weight) {
                    data["weight"] = item.weight;
                  }
                  if (item.height) {
                    data["height"] = item.height;
                  }
                  if (item.time) {
                    data["duration"] = item.time;
                  }
                  if (item.weightType) {
                    data["weight_unit"] = item.weightType;
                  }
                  if (item.heightType) {
                    data["height_unit"] = item.heightType;
                  }
                  if (item.distance) {
                    data["distance"] = item.distance;
                  }
                  if (item.distanceType) {
                    data["distance_unit"] = item.distanceType;
                  }
                  if (item.timeType) {
                    data["duration_unit"] = item.timeType;
                  }
                  if (item.restType) {
                    data["rest_unit"] = item.restType;
                  }
                  if (item.tempo) {
                    data["tempo"] = item.tempo;
                  }
                  return data;
                });
              }
            }
            if (item.type == "emom" || item.type == "amrap") {
              data["emom_amrap"] = [];
              if (item.emomSets.length > 0) {
                item.emomSets.map((item1, ind) => {
                  console.log(item1, "sjdkfksjdka");
                  if (item1.length > 0) {
                    data["emom_amrap"][ind] = item1.map((item2) => {
                      if (isError) {
                        return;
                      }
                      if (!item2.exercise?._id) {
                        if (item.type == "emom") {
                          enqueueSnackbar(
                            `please select an exercise in EMOM,  EMOM placed on ${
                              mainIn + 1
                            } position. `,
                            {
                              variant: "error",
                            }
                          );
                        }
                        if (item.type == "amrap") {
                          enqueueSnackbar(
                            `please select an exercise in AMRAP, AMRAP placed on ${
                              mainIn + 1
                            } position. `,
                            {
                              variant: "error",
                            }
                          );
                        }

                        isError = true;

                        return;
                      }
                      let data = {
                        exercise_info: {
                          _id: item2.exercise?._id,
                          title: item2.exercise?.title,
                          image: item2.exercise?.image,
                        },
                        title: item2.exercise?.title,
                        description: item2.description,
                        parameters: item2.parameters,
                      };
                      if (item2.set) {
                        data["sets"] = [];
                        if (item2.set.length > 0) {
                          data["sets"] = item2.set.map((item3) => {
                            let data = {};
                            if (item3.race_type) {
                              data["race_type"] = item3.race_type;
                            }
                            if (item3.pace) {
                              data["pace_low"] = item3.pace;
                            }
                            if (item3.mhr) {
                              data["mhr"] = item3.mhr;
                            }
                            if (item3.reps) {
                              data["reps"] = item3.reps;
                            }
                            if (item3.rest) {
                              data["rest"] = item3.rest;
                            }
                            if (item3.weight) {
                              data["weight"] = item3.weight;
                            }
                            if (item3.height) {
                              data["height"] = item3.height;
                            }
                            if (item3.time) {
                              data["duration"] = item3.time;
                            }
                            if (item3.weightType) {
                              data["weight_unit"] = item3.weightType;
                            }
                            if (item3.heightType) {
                              data["height_unit"] = item3.heightType;
                            }
                            if (item3.distance) {
                              data["distance"] = item3.distance;
                            }
                            if (item3.distanceType) {
                              data["distance_unit"] = item3.distanceType;
                            }
                            if (item3.timeType) {
                              data["duration_unit"] = item3.timeType;
                            }
                            if (item3.restType) {
                              data["rest_unit"] = item3.restType;
                            }
                            if (item3.tempo) {
                              data["tempo"] = item3.tempo;
                            }
                            return data;
                          });
                        }
                      }

                      return data;
                    });
                  } else {
                    if (item.type == "emom") {
                      enqueueSnackbar(
                        `please add a Movement on ${
                          ind + 1
                        } Interval in EMOM, EMOM placed on ${
                          mainIn + 1
                        } position.`,
                        {
                          variant: "error",
                        }
                      );
                    }
                    if (item.type == "amrap") {
                      enqueueSnackbar(
                        `please add a Movement in AMRAP,  placed on ${
                          mainIn + 1
                        } position.`,
                        {
                          variant: "error",
                        }
                      );
                    }

                    isError = true;

                    return;
                  }
                });
              }
            }
            if (item.superSet) {
              data["sub_exercises"] = [];
              if (item.superSet.length > 0) {
                data["sub_exercises"] = item.superSet.map((item) => {
                  if (isError) {
                    return;
                  }
                  if (!item.exercise?._id) {
                    enqueueSnackbar(
                      `please select an exercise in Superset,  placed on ${
                        mainIn + 1
                      } position.`,
                      {
                        variant: "error",
                      }
                    );

                    isError = true;
                    return;
                  }
                  let data = {
                    exercise_info: {
                      _id: item.exercise?._id,
                      title: item.exercise?.title,
                      image: item.exercise?.image,
                    },
                    title: item.exercise?.title,
                    description: item.description,
                    parameters: item.parameters,
                  };
                  if (item.set) {
                    data["sets"] = [];
                    if (item.set.length > 0) {
                      data["sets"] = item.set.map((item) => {
                        let data = {};
                        if (item.race_type) {
                          data["race_type"] = item.race_type;
                        }
                        if (item.pace) {
                          data["pace_low"] = item.pace;
                        }
                        if (item.mhr) {
                          data["mhr"] = item.mhr;
                        }
                        if (item.reps) {
                          data["reps"] = item.reps;
                        }
                        if (item.rest) {
                          data["rest"] = item.rest;
                        }
                        if (item.weight) {
                          data["weight"] = item.weight;
                        }
                        if (item.height) {
                          data["height"] = item.height;
                        }
                        if (item.time) {
                          data["duration"] = item.time;
                        }
                        if (item.weightType) {
                          data["weight_unit"] = item.weightType;
                        }
                        if (item.heightType) {
                          data["height_unit"] = item.heightType;
                        }
                        if (item.distance) {
                          data["distance"] = item.distance;
                        }
                        if (item.distanceType) {
                          data["distance_unit"] = item.distanceType;
                        }
                        if (item.timeType) {
                          data["duration_unit"] = item.timeType;
                        }
                        if (item.restType) {
                          data["rest_unit"] = item.restType;
                        }
                        if (item.tempo) {
                          data["tempo"] = item.tempo;
                        }
                        return data;
                      });
                    }
                  }

                  return data;
                });
              }
            }
            return data;
          });
    if (isError) {
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }
    const formDataObject = {
      title: workoutTitle,

      description: workoutDescription,

      exercises: exerciesArray,
      admin_comment: adminComment,

      program_id: props.programme?._id?._id,
      member_id: props?.member_id,

      workout_date: moment(props.date).format("YYYY-MM-DD"),
      practice_type: practiceType ? practiceType : "workout",
      video_url: inputs.video_url,
      video_thumbnail: inputs.image,
    };
    const formData = new FormData();

    formData.append("title", formDataObject.title);
    formData.append("description", formDataObject.description);
    formData.append("admin_comment", formDataObject.admin_comment);
    formData.append("program_id", formDataObject.program_id);
    formData.append("member_id", formDataObject.member_id);
    formData.append("workout_date", formDataObject.workout_date);
    formData.append("practice_type", formDataObject.practice_type);
    formData.append(
      "video_url",
      formDataObject.video_url ? formDataObject.video_url : ""
    );

    formData.append("exercises", JSON.stringify(formDataObject.exercises));
    if (imageStatus) {
      formData.append("video_thumbnail", formDataObject.video_thumbnail);
    }
    if (props?.practice_type == "video") {
      formData.append("video_only_type", JSON.stringify(props.multipleTypes));
    }
    console.log(formDataObject, "dslfkdjsaa3", ...formData);

    const result = await add_edit_program_workout_member_api(formData);
    if (result.code === 200) {
      // props?.reloadList();
      props.setMultipleType([]);
      props?.setEditWorkputData(result?.workout);
      const workout = result?.workout;
      let newData = {
        ...workout,
        title: workout.title ? workout.title : "",
        color: workout.program_info?.color,
        workout_color: workout.program_info?.color,
        workout_title: workout.title ? workout.title : "",
        workout_exercises: workout.exercises,
        workout_program: workout.program_info,
        is_deleteAble: workout?.workout_has_logs ? false : true,
        workout_completed: workout?.workout_completed,
        status: workout.status,
        open: true,
        date: moment(workout.workout_date).format("YYYY-MM-DD"),
        index: props?.events.length - 1,
        editable: workout?.workout_has_logs ? false : true,
        dumyImage: file,
      };
      let list = [...props?.events, newData];
      props?.setEvents(list);
      handleScrollCalled(workout?._id);

      setIsLoading(false);
      props.setIsLoading(false);
      props.setOpen(false);
      enqueueSnackbar(result.message, { variant: "success" });
      return formDataObject;
    } else {
      setIsLoading(false);
      props.setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmitUpdate = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    props.setIsLoading(true);
    let isError = false;
    if (
      urlPatternValidation(inputs.video_url) === false &&
      inputs.video_url != ""
    ) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
      isError = true;
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }

    if (inputs.video_url == "" && (inputs.image || oldImage)) {
      enqueueSnackbar("Please Enter Video URL", {
        variant: "error",
      });
      isError = true;
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }

    if (inputs.video_url && !inputs.image && !oldImage) {
      enqueueSnackbar("Please Upload Video Thumbnail", { variant: "error" });
      isError = true;
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }
    let fullArray = [...addArray, ...addArrayExtra];
    console.log(fullArray, "adkjfklsdhkjfah");
    let exerciesArray =
      practiceType == "video"
        ? []
        : fullArray.map((item, mainIn) => {
            if (
              item.type != "emom" &&
              item.type != "amrap" &&
              item.type != "something_else" &&
              !item.exercise?._id &&
              item.superSet.length == 0
            ) {
              enqueueSnackbar("please select an exercise", {
                variant: "error",
              });
              isError = true;
            }
            if (item.type == "something_else" && !item?.title) {
              enqueueSnackbar("please add title", {
                variant: "error",
              });
              isError = true;
            }
            let data = {
              is_completed: item?.is_completed,
              is_modified: item?.is_modified,
              is_extra: item?.is_extra,
              exercise_info: {
                _id: item?.superSet[0]?.exercise?._id
                  ? item?.superSet[0]?.exercise?._id
                  : item.exercise?._id,
                title: item?.superSet[0]?.exercise?.title
                  ? item?.superSet[0]?.exercise?.title
                  : item.exercise?.title,
                image: item?.superSet[0]?.exercise?.image
                  ? item?.superSet[0]?.exercise?.image
                  : item.exercise?.image,
              },
              title: item?.title,
              description: item.description,
              parameters: item.parameters,
            };
            if (item?._id) {
              data["_id"] = item?._id;
            }
            if (item?.last_amrap_exercise) {
              data["last_amrap_exercise"] = item?.last_amrap_exercise;
            }
            if (item?.last_amrap_exercise_rounds) {
              data["last_amrap_exercise_rounds"] =
                item?.last_amrap_exercise_rounds;
            }
            if (item.completedRounds) {
              data["amrap_completed_rounds"] = item.completedRounds;
            }
            if (item.superset_sets) {
              data["superset_sets"] = item.superset_sets;
            }
            if (item.rounds) {
              data["emom_rounds"] = item.rounds;
            }
            if (item.type) {
              data["exercise_type"] = item.type;
            }
            if (item.time_interval) {
              data["interval_duration"] = item.time_interval;
            }
            if (item.set) {
              data["sets"] = [];
              if (item.set.length > 0) {
                data["sets"] = item.set.map((item) => {
                  let data = {};
                  if (item.mhr) {
                    data["mhr"] = item.mhr;
                  }
                  if (item.vdot) {
                    data["vdot"] = item.vdot;
                  }
                  if (item.race_type) {
                    data["race_type"] = item.race_type;
                  }
                  if (item.pace_type) {
                    data["pace_type"] = item.pace_type;
                  }
                  if (item.pace) {
                    data["pace_low"] = item.pace;
                  }
                  if (item.paceUp) {
                    data["pace_up"] = item.paceUp;
                  }
                  if (item.optionsType) {
                    data["pace_options"] = item.optionsType;
                  }
                  if (item.reps) {
                    data["reps"] = item.reps;
                  }
                  if (item.rest) {
                    data["rest"] = item.rest;
                  }
                  if (item.weight) {
                    data["weight"] = item.weight;
                  }
                  if (item.height) {
                    data["height"] = item.height;
                  }
                  if (item.time) {
                    data["duration"] = item.time;
                  }
                  if (item.weightType) {
                    data["weight_unit"] = item.weightType;
                  }
                  if (item.heightType) {
                    data["height_unit"] = item.heightType;
                  }
                  if (item.distance) {
                    data["distance"] = item.distance;
                  }
                  if (item.distanceType) {
                    data["distance_unit"] = item.distanceType;
                  }
                  if (item.timeType) {
                    data["duration_unit"] = item.timeType;
                  }
                  if (item.restType) {
                    data["rest_unit"] = item.restType;
                  }
                  if (item.tempo) {
                    data["tempo"] = item.tempo;
                  }
                  return data;
                });
              }
            }
            if (item.type == "emom" || item.type == "amrap") {
              data["emom_amrap"] = [];
              if (item.emomSets.length > 0) {
                item.emomSets.map((item1, ind) => {
                  console.log(item1, "sjdkfksjdka");
                  if (item1.length > 0) {
                    data["emom_amrap"][ind] = item1.map((item2) => {
                      if (isError) {
                        return;
                      }
                      if (!item2.exercise?._id) {
                        if (item.type == "emom") {
                          enqueueSnackbar(
                            `please select an exercise in EMOM,  EMOM placed on ${
                              mainIn + 1
                            } position. `,
                            {
                              variant: "error",
                            }
                          );
                        }
                        if (item.type == "amrap") {
                          enqueueSnackbar(
                            `please select an exercise in AMRAP, AMRAP placed on ${
                              mainIn + 1
                            } position. `,
                            {
                              variant: "error",
                            }
                          );
                        }

                        isError = true;

                        return;
                      }
                      let data = {
                        exercise_info: {
                          _id: item2.exercise?._id,
                          title: item2.exercise?.title,
                          image: item2.exercise?.image,
                        },
                        title: item2.exercise?.title,
                        description: item2.description,
                        parameters: item2.parameters,
                      };
                      if (item2?._id) {
                        data["_id"] = item2?._id;
                      }
                      if (item2.set) {
                        data["sets"] = [];
                        if (item2.set.length > 0) {
                          data["sets"] = item2.set.map((item3) => {
                            let data = {};
                            if (item3.mhr) {
                              data["mhr"] = item3.mhr;
                            }
                            if (item3.race_type) {
                              data["race_type"] = item3.race_type;
                            }
                            if (item3.pace) {
                              data["pace_low"] = item3.pace;
                            }
                            if (item3.mhr) {
                              data["mhr"] = item3.mhr;
                            }
                            if (item3.reps) {
                              data["reps"] = item3.reps;
                            }
                            if (item3.rest) {
                              data["rest"] = item3.rest;
                            }
                            if (item3.weight) {
                              data["weight"] = item3.weight;
                            }
                            if (item3.height) {
                              data["height"] = item3.height;
                            }
                            if (item3.time) {
                              data["duration"] = item3.time;
                            }
                            if (item3.weightType) {
                              data["weight_unit"] = item3.weightType;
                            }
                            if (item3.heightType) {
                              data["height_unit"] = item3.heightType;
                            }
                            if (item3.distance) {
                              data["distance"] = item3.distance;
                            }
                            if (item3.distanceType) {
                              data["distance_unit"] = item3.distanceType;
                            }
                            if (item3.timeType) {
                              data["duration_unit"] = item3.timeType;
                            }
                            if (item3.restType) {
                              data["rest_unit"] = item3.restType;
                            }
                            if (item3.tempo) {
                              data["tempo"] = item3.tempo;
                            }
                            return data;
                          });
                        }
                      }

                      return data;
                    });
                  } else {
                    if (item.type == "emom") {
                      enqueueSnackbar(
                        `please add a Movement on ${
                          ind + 1
                        } Interval in EMOM, EMOM placed on ${
                          mainIn + 1
                        } position.`,
                        {
                          variant: "error",
                        }
                      );
                    }
                    if (item.type == "amrap") {
                      enqueueSnackbar(
                        `please add a Movement in AMRAP,  placed on ${
                          mainIn + 1
                        } position.`,
                        {
                          variant: "error",
                        }
                      );
                    }

                    isError = true;

                    return;
                  }
                });
              }
            }
            if (item.superSet) {
              data["sub_exercises"] = [];
              if (item.superSet.length > 0) {
                data["sub_exercises"] = item.superSet.map((item) => {
                  if (isError) {
                    return;
                  }
                  if (!item.exercise?._id) {
                    enqueueSnackbar(
                      `please select an exercise in Superset,  placed on ${
                        mainIn + 1
                      } position.`,
                      {
                        variant: "error",
                      }
                    );

                    isError = true;
                    return;
                  }
                  let data = {
                    is_completed: item?.is_completed,
                    is_modified: item?.is_modified,
                    is_extra: item?.is_extra,

                    exercise_info: {
                      _id: item.exercise?._id,
                      title: item.exercise?.title,
                      image: item.exercise?.image,
                    },
                    title: item.exercise?.title,
                    description: item.description,
                    parameters: item.parameters,
                  };
                  if (item?._id) {
                    data["_id"] = item?._id;
                  }
                  if (item.set) {
                    data["sets"] = [];
                    if (item.set.length > 0) {
                      data["sets"] = item.set.map((item) => {
                        let data = {};
                        if (item.mhr) {
                          data["mhr"] = item.mhr;
                        }
                        if (item.race_type) {
                          data["race_type"] = item.race_type;
                        }
                        if (item.pace) {
                          data["pace_low"] = item.pace;
                        }
                        if (item.mhr) {
                          data["mhr"] = item.mhr;
                        }
                        if (item.reps) {
                          data["reps"] = item.reps;
                        }
                        if (item.rest) {
                          data["rest"] = item.rest;
                        }
                        if (item.weight) {
                          data["weight"] = item.weight;
                        }
                        if (item.height) {
                          data["height"] = item.height;
                        }
                        if (item.time) {
                          data["duration"] = item.time;
                        }
                        if (item.weightType) {
                          data["weight_unit"] = item.weightType;
                        }
                        if (item.heightType) {
                          data["height_unit"] = item.heightType;
                        }
                        if (item.distance) {
                          data["distance"] = item.distance;
                        }
                        if (item.distanceType) {
                          data["distance_unit"] = item.distanceType;
                        }
                        if (item.timeType) {
                          data["duration_unit"] = item.timeType;
                        }
                        if (item.restType) {
                          data["rest_unit"] = item.restType;
                        }
                        if (item.tempo) {
                          data["tempo"] = item.tempo;
                        }
                        return data;
                      });
                    }
                  }

                  return data;
                });
              }
            }
            return data;
          });
    if (isError) {
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }
    console.log(inputs, "inputsinputsinputs");
    const formDataObject = {
      title: workoutTitle,

      description: workoutDescription,

      admin_comment: adminComment,
      exercises: exerciesArray,
      program_id: props.editData?.program_info?.program_id,
      member_id: props?.member_id,
      _id: props?.editData?._id,
      workout_date: moment(props.date).format("YYYY-MM-DD"),
      video_url: inputs.video_url,
      video_thumbnail: inputs.image,
    };
    const formData = new FormData();

    formData.append("title", formDataObject.title);
    formData.append("description", formDataObject.description);
    formData.append("admin_comment", formDataObject.admin_comment);
    formData.append("program_id", formDataObject.program_id);
    formData.append("member_id", formDataObject.member_id);
    formData.append("_id", formDataObject._id);
    formData.append("workout_date", formDataObject.workout_date);
    formData.append(
      "video_url",
      formDataObject.video_url ? formDataObject.video_url : ""
    );

    formData.append("exercises", JSON.stringify(formDataObject.exercises));
    if (imageStatus) {
      formData.append("video_thumbnail", formDataObject.video_thumbnail);
    }
    if (props?.editData?.practice_type == "video") {
      formData.append(
        "video_only_type",
        JSON.stringify(props?.editData?.video_only_type)
      );
    }
    console.log(formDataObject, "dslfkdjsaa1", ...formData);
    const result = await add_edit_program_workout_member_api(formData);
    if (result.code === 200) {
      // props?.reloadList();

      const workout = result?.workout;
      let newData = {
        ...workout,
        title: workout.title ? workout.title : "",
        color: workout.program_info?.color,
        workout_color: workout.program_info?.color,
        workout_title: workout.title ? workout.title : "",
        workout_exercises: workout.exercises,
        workout_program: workout.program_info,
        is_deleteAble: workout?.workout_has_logs ? false : true,
        workout_completed: workout?.workout_completed,
        status: workout.status,
        open: true,
        date: moment(workout.workout_date).format("YYYY-MM-DD"),
        index: props?.events.length - 1,
        editable: workout?.workout_has_logs ? false : true,
        dumyImage: file,
      };
      let list = [...props?.events];
      let indexToUpdate = list.findIndex((item) => item._id === newData?._id);

      if (indexToUpdate !== -1) {
        list[indexToUpdate] = newData;
      }
      props?.setEvents(list);
      handleScrollCalled(workout?._id);

      setIsLoading(false);
      props.setIsLoading(false);
      // props?.setOpen(false);

      enqueueSnackbar(result.message, { variant: "success" });
      return formDataObject;
    } else {
      setIsLoading(false);
      props.setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmitJustComment = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    props.setIsLoading(true);
    let isError = false;

    if (isError) {
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }
    const formDataObject = {
      admin_comment: adminComment,

      member_id: props?.member_id,
      _id: props?.editData?._id,
    };
    console.log(formDataObject, "dsafjhkjsdjfa");
    const result = await add_edit_program_workout_Reply_member_api(
      formDataObject
    );
    if (result.code === 200) {
      props?.reloadList();
      setIsLoading(false);
      props.setIsLoading(false);
      props?.setOpen(false);

      enqueueSnackbar(result.message, { variant: "success" });
      return formDataObject;
    } else {
      setIsLoading(false);
      props.setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);
    setaddArray(items);
  };

  const handleSelectExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;
    setaddArray(list);
  };
  const handleChangeDown = (value) => {
    console.log(value, "fkdshjkfahdkjha", addArray.length);
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);
    setaddArray(items);
  };

  useEffect(() => {
    // getExerciseList();
  }, [serchText]);
  useEffect(() => {
    console.log(props?.editData, "dsfkjhsjkdfhjasdjkf");
    setPreviousRouteHandler(pathname);
    setInputs({
      ...inputs,
      ["video_url"]: props?.editData?.video_url,
    });

    setOldImage(props?.editData?.video_thumbnail?.thumbnail_1);
    if (props?.editData) {
      setPracticeType(
        props?.editData?.practice_type
          ? props?.editData?.practice_type
          : "workout"
      );
      setWorkoutTitle(
        props?.editData?.workout_title
          ? props?.editData?.workout_title
          : props?.editData?.title
      );
      setWorkoutDescription(props?.editData?.description);
      setCommnet(props?.editData?.comment);
      setAdminCommnet(props?.editData?.admin_comment);
      setExercise(props?.editData?.exercises);
      let givenExercise = props?.editData?.exercises
        .map((item) => {
          if (!item?.is_extra) {
            let SubSet = item?.sub_exercises?.map((item) => {
              return {
                _id: item?._id,
                exercise: item?.exercise_info,
                is_completed: item?.is_completed,
                is_modified: item?.is_modified,
                is_extra: item?.is_extra,
                set: item?.sets.map((item1) => {
                  return {
                    time: item1?.duration,
                    weight: item1?.weight,
                    weightType: item1?.weight_unit,
                    height: item1?.height,
                    heightType: item1?.height_unit,
                    reps: item1?.reps,
                    rest: item1?.rest,
                    distance: item1?.distance,
                    distanceType: item1?.distance_unit,
                    timeType: item1?.duration_unit,
                    restType: item1?.rest_unit,
                    tempo: item1?.tempo,
                    mhr: item1?.mhr,
                    race_type: item1?.race_type ? item1?.race_type : "c",
                    pace: item1?.pace_low ? item1?.pace_low : 0,
                  };
                }),
                rounds: 10,
                emomSets: [[]],
                type: item.exercise_type ? item.exercise_type : "general",
                parameters: item?.parameters,
                description: item?.description,
                superSet: [],
              };
            });
            let emomAndAmrapSets = item?.emom_amrap?.map((item1, ind) => {
              let interval = item1?.map((item) => {
                return {
                  _id: item?._id,
                  exercise: item?.exercise_info,
                  set: item?.sets.map((item1) => {
                    return {
                      time: item1?.duration,
                      weight: item1?.weight,
                      weightType: item1?.weight_unit,
                      height: item1?.height,
                      heightType: item1?.height_unit,
                      reps: item1?.reps,
                      rest: item1?.rest,
                      distance: item1?.distance,
                      distanceType: item1?.distance_unit,
                      timeType: item1?.duration_unit,
                      restType: item1?.rest_unit,
                      tempo: item1?.tempo,
                      mhr: item1?.mhr,
                      race_type: item1?.race_type ? item1?.race_type : "c",
                      pace: item1?.pace_low ? item1?.pace_low : 0,
                    };
                  }),

                  parameters: item?.parameters,
                  description: item?.description,
                  superSet: [],
                };
              });
              return interval;
            });
            return {
              _id: item?._id,
              last_amrap_exercise: item?.last_amrap_exercise,
              last_amrap_exercise_rounds: item?.last_amrap_exercise_rounds,
              exercise: item?.exercise_info,
              is_completed: item?.is_completed,
              is_modified: item?.is_modified,
              is_extra: item?.is_extra,
              title: item.title,
              set: item?.sets.map((item1) => {
                return {
                  mhr: item1?.mhr,
                  time: item1?.duration,
                  weight: item1?.weight,
                  weightType: item1?.weight_unit,
                  height: item1?.height,
                  heightType: item1?.height_unit,
                  reps: item1?.reps,
                  rest: item1?.rest,
                  distance: item1?.distance,
                  distanceType: item1?.distance_unit,
                  timeType: item1?.duration_unit,
                  restType: item1?.rest_unit,
                  tempo: item1?.tempo,
                  vdot: item1?.vdot
                    ? item1?.vdot
                    : props?.member_vdot
                    ? props?.member_vdot
                    : null,
                  race_type: item1?.race_type ? item1?.race_type : "c",
                  pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                  pace: item1?.pace_low
                    ? item1?.pace_low
                    : props?.member_vdot
                    ? vdot33Object[
                        item1?.race_type == "el"
                          ? item1?.pace_type + "_low"
                          : item1?.pace_type
                      ]
                      ? vdot33Object[
                          item1?.race_type == "el"
                            ? item1?.pace_type + "_low"
                            : item1?.pace_type
                        ]
                      : 0
                    : 0,
                  paceUp: item1?.pace_up
                    ? item1?.pace_up
                    : props?.member_vdot
                    ? vdot33Object[
                        item1?.race_type == "el"
                          ? item1?.pace_type + "_up"
                          : item1?.pace_type
                      ]
                      ? vdot33Object[
                          item1?.race_type == "el"
                            ? item1?.pace_type + "_up"
                            : item1?.pace_type
                        ]
                      : 0
                    : 0,
                  optionsType:
                    item1?.pace_options && item1?.pace_options.length > 0
                      ? item1?.pace_options
                      : [
                          { title: "1 KM Pace", key: "c_km" },
                          { title: "1 MILE Pace", key: "c_mi" },
                        ],
                };
              }),

              rounds: item.emom_rounds ? item.emom_rounds : 10,
              completedRounds: item.amrap_completed_rounds,
              type: item.exercise_type ? item.exercise_type : "general",
              time_interval: item.interval_duration,
              emomSets:
                emomAndAmrapSets && emomAndAmrapSets.length > 0
                  ? emomAndAmrapSets
                  : [[]],
              parameters: item?.parameters,
              description: item?.description,
              superSet: SubSet ? SubSet : [],
              superset_sets: item?.superset_sets,
            };
          }
          return [];
        })
        .flat();
      let givenExerciseExtra = props?.editData?.exercises
        .map((item) => {
          if (item?.is_extra) {
            let SubSet = item?.sub_exercises?.map((item) => {
              return {
                _id: item?._id,
                exercise: item?.exercise_info,
                is_completed: item?.is_completed,
                is_modified: item?.is_modified,
                is_extra: item?.is_extra,
                set: item?.sets.map((item1) => {
                  return {
                    time: item1?.duration,
                    weight: item1?.weight,
                    weightType: item1?.weight_unit,
                    height: item1?.height,
                    heightType: item1?.height_unit,
                    reps: item1?.reps,
                    rest: item1?.rest,
                    distance: item1?.distance,
                    distanceType: item1?.distance_unit,
                    timeType: item1?.duration_unit,
                    restType: item1?.rest_unit,
                    tempo: item1?.tempo,
                  };
                }),
                description: item?.description,
                parameters: item?.parameters,
                superSet: [],
              };
            });

            return {
              _id: item?._id,
              exercise: item?.exercise_info,
              is_completed: item?.is_completed,
              is_modified: item?.is_modified,
              is_extra: item?.is_extra,
              set: item?.sets.map((item1) => {
                return {
                  time: item1?.duration,
                  weight: item1?.weight,
                  weightType: item1?.weight_unit,
                  height: item1?.height,
                  heightType: item1?.height_unit,
                  reps: item1?.reps,
                  rest: item1?.rest,
                  distance: item1?.distance,
                  distanceType: item1?.distance_unit,
                  timeType: item1?.duration_unit,
                  restType: item1?.rest_unit,
                  tempo: item1?.tempo,
                };
              }),
              parameters: item?.parameters,
              description: item?.description,
              superSet: SubSet,
              superset_sets: item?.superset_sets,
            };
          }
          return [];
        })
        .flat();

      console.log(givenExercise, "sdkfhjkdshkafj");
      setaddArray(givenExercise);
      setaddArrayExtra(givenExerciseExtra);
    }
  }, [props?.editData]);
  useEffect(() => {
    // Skip the first render
    if (props?.editData?.practice_type == "video") {
      props?.setIsLoading(false);
    } else if (!addArray.length > 0) {
      props.setIsLoading(true);
    }
  }, [addArray]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div>
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <div className=" ">
        <div className="row">
          {" "}
          <div className="col-12">
            {/* <h1 className="programmes-heading">{state?.title}</h1> */}
            <FormControl
              className={!isFocused ? classes.customInput : ""}
              sx={{
                width: { sm: "100%", md: "70%" },
              }}>
              <TextField
                id="outlined-basic"
                // label="Exercise"
                variant={"outlined"}
                onFocus={handleFocus}
                className="title-Test"
                onBlur={handleBlur}
                placeholder={`${convertToTitleCase(practiceType)} Name !`}
                value={workoutTitle}
                name="workoutTitle"
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    setWorkoutTitle(e.target.value);
                    props.setModalChangeExe(true);
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "18px" },
                }}
                InputProps={{
                  readOnly: props?.editData?.workout_has_logs
                    ? props?.editData?.workout_has_logs
                    : false,

                  style: {
                    fontSize: "32px",
                    fontWeight: "500",
                  },
                }}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },

                  fontSize: "13px",
                  // width: "10%",
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={!isFocused1 ? classes.customInput : ""}>
              <TextField
                id="outlined-basic"
                className="desc-Test"
                // label="Exercise"
                variant={"outlined"}
                onFocus={handleFocus1}
                onBlur={handleBlur1}
                placeholder={`${convertToTitleCase(
                  practiceType
                )} description !`}
                value={workoutDescription}
                multiline
                rows={3}
                name="workoutTitle"
                onChange={(e) => {
                  if (e.target.value.length <= 250)
                    setWorkoutDescription(e.target.value);
                  props.setModalChangeExe(true);
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "18px" },
                }}
                InputProps={{
                  readOnly: props?.editData?.workout_has_logs
                    ? props?.editData?.workout_has_logs
                    : false,
                  style: {
                    fontSize: "14px",
                    fontWeight: "500",
                    padding: 0,
                  },
                  maxLength: 10,
                }}
                sx={{
                  borderRadius: "5px",

                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  mb: 1,
                  // width: "10%",
                }}
              />
            </FormControl>
          </div>
        </div>
        {props?.editData?.workout_has_logs ? (
          <div className="row my-3">
            <div className="col-lg-5 col-md-5 col-sm-12 ">
              <Tooltip
                placement="top"
                touch="on"
                title={
                  "Because member have responded, that is why you can't edit it."
                }>
                <TextField
                  id="outlined-basic"
                  label="Video Url"
                  variant="outlined"
                  fullWidth
                  name="video_url"
                  disabled={
                    props?.editData?.workout_has_logs
                      ? props?.editData?.workout_has_logs
                      : false
                  }
                  value={inputs.video_url}
                  onChange={handleChange}
                />
              </Tooltip>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <p className="mb-0">
                    {practiceType == "video"
                      ? "Upload Video Thumbnail *"
                      : "Upload Video Thumbnail"}{" "}
                  </p>
                  <FormHelperText className="pt-0" sx={{ fontSize: 9 }}>
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className=" col-lg-2 col-md-2 col-sm-12">
                  {file ? (
                    <img className="image-border" src={file} height="50" />
                  ) : oldImage ? (
                    <img
                      className="image-border"
                      src={s3baseUrl + oldImage}
                      height="50"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12  text-lg-end text-md-end text-sm-start pt-2">
                  <Tooltip
                    placement="top"
                    touch="on"
                    title={
                      "Because member have responded, that is why you can't edit it."
                    }>
                    <label htmlFor="contained-button-file">
                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                        variant="contained"
                        size="small"
                        disabled={
                          props?.editData?.workout_has_logs
                            ? props?.editData?.workout_has_logs
                            : false
                        }>
                        Upload
                      </Button>
                    </label>
                  </Tooltip>
                </div>
              </div>
              {inputs?.image?.name == "" ? (
                ""
              ) : (
                <p className="text-secondary">{inputs?.image?.name}</p>
              )}
            </div>
          </div>
        ) : (
          <div className="row my-3">
            <div className="col-11 video-text">
              <p>
                The video URL and the thumbnail image are linked, as adding a
                video URL requires a thumbnail and vice versa. The thumbnail
                will appear on the calendar, and clicking it will open the
                popup.
              </p>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 ">
              <TextField
                id="outlined-basic"
                label="Video Url"
                variant="outlined"
                required={practiceType == "video"}
                fullWidth
                name="video_url"
                disabled={
                  props?.editData?.workout_has_logs
                    ? props?.editData?.workout_has_logs
                    : false
                }
                value={inputs.video_url}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <p className="mb-0">
                    {practiceType == "video"
                      ? "Upload Video Thumbnail *"
                      : "Upload Video Thumbnail"}{" "}
                  </p>
                  <FormHelperText className="pt-0" sx={{ fontSize: 9 }}>
                    Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className=" col-lg-2 col-md-2 col-sm-12">
                  {file ? (
                    <img className="image-border" src={file} height="50" />
                  ) : oldImage ? (
                    <img
                      className="image-border"
                      src={s3baseUrl + oldImage}
                      height="50"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12  text-lg-end text-md-end text-sm-start pt-2">
                  <label htmlFor="contained-button-file">
                    <input
                      accept=".jpg, .jpeg, .png, .webp"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      required
                      onChange={(e) => fileChangedHandler(e)}
                      style={{ display: "none" }}
                    />

                    <Button
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span"
                      variant="contained"
                      size="small"
                      disabled={
                        props?.editData?.workout_has_logs
                          ? props?.editData?.workout_has_logs
                          : false
                      }>
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {inputs?.image?.name == "" ? (
                ""
              ) : (
                <p className="text-secondary">{inputs?.image?.name}</p>
              )}
            </div>
          </div>
        )}
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          className="h-100"
          isDragDisabled={true}>
          <Droppable droppableId="characters" isDragDisabled={true}>
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {/* <form onSubmit={handleSubmit}> */}

                <form onSubmit={() => {}}>
                  {props?.editData?.workout_has_logs ? (
                    practiceType == "workout" ? (
                      <WorkoutExercisesUILogedClient
                        addArray={addArray}
                        setaddArray={setaddArray}
                        setModalChangeExe={props.setModalChangeExe}
                        memberId={props?.member_id}
                        isPersonal={true}
                        message={
                          "Because member have responded, that is why you can't edit it."
                        }
                      />
                    ) : practiceType == "video" ? (
                      <></>
                    ) : (
                      <AnswerdFoodMattersAndMindsetUI
                        addArray={addArray}
                        setaddArray={setaddArray}
                        setModalChangeExe={props.setModalChangeExe}
                        mainindex={0}
                        message={
                          "Because member have responded, that is why you can't edit it."
                        }
                      />
                    )
                  ) : (
                    <>
                      {addArray.length > 0 ? (
                        <>
                          {practiceType == "workout" ? (
                            <div class="row">
                              <div
                                class={
                                  props?.action_type == "ADD"
                                    ? "col-12 col-lg-8 p-0"
                                    : "col-12"
                                }>
                                <WorkoutExercisesUI
                                  addArray={addArray}
                                  setaddArray={setaddArray}
                                  setModalChangeExe={props.setModalChangeExe}
                                  isHistory={true}
                                  memberId={props?.member_id}
                                  member_vdot={props?.member_vdot}
                                  isWithHistoryAndBigScreen={
                                    props?.action_type == "ADD"
                                      ? is_LG_screen()
                                      : false
                                  }
                                />
                              </div>
                              {props?.action_type == "ADD" && is_LG_screen() && (
                                <div class="col-12 col-lg-4 pe-0">
                                  <span className="fixed-position">
                                    <h5 className="title-color m-0">History</h5>
                                    {userExerciseHistoryLoading ? (
                                      <CircularProgress
                                        className={classes.loading}
                                        color="primary"
                                      />
                                    ) : (
                                      <div className="fixed-side-history">
                                        {userExerciseHistory.length > 0 ? (
                                          <WorkoutExercisesHistoryUI
                                            addArray={userExerciseHistory}
                                            setaddArray={setUserExerciseHistory}
                                            notForModel={true}
                                          />
                                        ) : (
                                          <RecordNotFound title={"History"} />
                                        )}
                                      </div>
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                          ) : practiceType == "video" ? (
                            <></>
                          ) : (
                            <FoodMattersAndMindsetUI
                              addArray={addArray}
                              setaddArray={setaddArray}
                              setModalChangeExe={props.setModalChangeExe}
                              mainindex={0}
                            />
                          )}
                        </>
                      ) : practiceType == "video" ? (
                        <></>
                      ) : (
                        <div className="text-end container my-4">
                          <button
                            className="small-contained-button "
                            onClick={() => {
                              handleAdd();
                            }}>
                            Add Exercise
                          </button>
                        </div>
                      )}
                    </>
                  )}

                  {addArrayExtra.length > 0 && (
                    <>
                      <div className="container my-4">
                        <h2>
                          <Icon icon="mdi:bicycle-electric" /> Extra work
                        </h2>
                        These exercises aren’t part of the original workout
                      </div>
                      <WorkoutExercisesUIClient
                        addArray={addArrayExtra}
                        setaddArray={setaddArrayExtra}
                        setModalChangeExe={props.setModalChangeExe}
                        message={
                          "Member's Extra Work! , that is why you can't edit it."
                        }
                      />
                    </>
                  )}
                  {comment && (
                    <div className="container">
                      <div
                        className=" my-3"
                        style={{
                          whiteSpace: "break-spaces",
                          fontSize: 14,
                          opacity: 0.7,
                          color: "black",
                        }}>
                        <span
                          style={{
                            opacity: 1,
                            fontWeight: "bold",
                            display: "block",
                            color: get_root_value("--portal-theme-primary"),
                          }}>
                          Comment
                        </span>
                        <div className="ms-0">{comment}</div>
                      </div>
                    </div>
                  )}
                  {comment && (
                    <div className=" my-2">
                      <TextField
                        id="outlined-basic"
                        // label="Exercise"
                        size="small"
                        variant="outlined"
                        placeholder="Add Reply here!"
                        label="Reply"
                        type="text"
                        value={adminComment}
                        name="description"
                        multiline
                        // readOnly={
                        //   props?.editData?.workout_has_logs ? true : false
                        // }
                        rows={5}
                        onChange={(e) => setAdminCommnet(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: "14px" },
                        }}
                        InputProps={{
                          style: { fontSize: "14px" },
                          // readOnly: props?.editData?.workout_has_logs
                          //   ? true
                          //   : false,
                        }}
                        sx={{
                          borderRadius: "5px",
                          // "& legend": { display: "none" },
                          // "& fieldset": { top: 0 },

                          mt: 2,
                          width: "100%",
                        }}
                      />
                    </div>
                  )}
                  {/* <div className="text-end mt-4">
                    <button className="small-contained-button">
                      {isLoading ? "Submitting..." : "Submit"}
                    </button>
                  </div> */}
                </form>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
});
export default AddWorkoutCalenderClient;
