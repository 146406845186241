import { invokeApi } from "../../bl_libs/invokeApi";

export const delete_program_workout_Api = async (slug) => {
  const requestObj = {
    path: `api/program_workout/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_program_workout_details_Api = async (id) => {
  const requestObj = {
    path: `api/program_workout/details_for_delete/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_program_workout_client_Api = async (data) => {
  const requestObj = {
    path: `api/member/delete_member_workout/by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_program_workout_api = async (data) => {
  const requestObj = {
    path: `api/program_workout`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_edit_program_workout_member_api = async (data) => {
  const requestObj = {
    path: `api/member/update_workouts/for_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_edit_program_workout_Reply_member_api = async (data) => {
  const requestObj = {
    path: `api/member/comment_to_member_workouts/by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const programme_workout_exercise_info_api = async (data) => {
  const requestObj = {
    path: `api/exercise/exercise_details_for_preview/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const update_program_workout_api = async (data, workout_slug) => {
  const requestObj = {
    path: `api/program_workout/${workout_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const programme_workout_list_api = async (data) => {
  const requestObj = {
    path: `api/program_workout/?start=${data.start_date}&end=${data.end_date}&program_slug=${data.slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programme_workout_exercise_history_list_api = async (data) => {
  const requestObj = {
    path: `api/member/member_exercise_history/by_admin?exercise_id=${data.exercise}&member_id=${data.member}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const reorder_events_api = async (data) => {
  const requestObj = {
    path: `api/program_workout/re_order`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const reorder_member_events_api = async (data) => {
  const requestObj = {
    path: `api/member/reorder_general_calendar_workout`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const paste_multiple_workouts = async (data) => {
  const requestObj = {
    path: `api/program_workout/copy_paste_workout`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const copy_paste_member_workout = async (data) => {
  const requestObj = {
    path: `api/member/copy_paste_workout`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
