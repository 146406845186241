import { CircularProgress, TextField, Tooltip, Box, Chip } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";

import { makeStyles } from "@mui/styles";
import TinyEditor from "src/components/ckeditor/Ckeditor";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const FoodMattersAndMindsetUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  mainindex,
}) => {
  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const handleChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleChangeDes = (value) => {
    const list = [...addArray];
    list[mainindex]["description"] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="my-2">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}

      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            <div
              style={{
                width: "100%",
                padding: "1px",
                position: "relative",
              }}
            >
              <div
                className=" my-1 workout-set-card w-100"
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div className="row">
                    <div
                      className={`col-sm-12 ${"col-md-7"} col-lg-7 d-flex align-items-center  mt-2 mt-md-0 mt-lg-0`}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Title"
                        value={
                          addArray[mainindex].title
                            ? addArray[mainindex].title
                            : ""
                        }
                        name="title"
                        onChange={(e) => handleChange(e, mainindex)}
                        InputLabelProps={{
                          className: "textfiels-input-label",
                          shrink: true,
                          style: { fontSize: "14px" },
                        }}
                        label="Title"
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "13px",
                          },

                          inputProps: {
                            // readOnly: true,
                          },
                        }}
                        sx={{
                          borderRadius: "5px",

                          fontSize: "13px",
                          width: "100%",
                        }}
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <h4>Detail Description</h4>
                      <TinyEditor
                        setDetailDescription={handleChangeDes}
                        detailDescriptionCk={addArray[mainindex].description}
                      />
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default FoodMattersAndMindsetUI;
