import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { login_by_admin_api } from "../DAL/Login/Login";

import { CircularProgress } from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LoginByAdmin() {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const {
    setUserInfo,
    // START_SOCKET_FORCEFULLY,
  } = useContentSetting();

  const handleSubmitLogin = async () => {
    setIsLoading(true);
    const data = {
      token: params.token,
      user_type: "coach",
    };
    const result = await login_by_admin_api(data);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result?.CoachUser));
      localStorage.setItem("token", result?.token);
      console.log(result, "dslfkjsdfkj");
      setUserInfo(result?.CoachUser);
      navigate("/programmes", { replace: true });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate("/login");
    }
  };

  useEffect(() => {
    handleSubmitLogin();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
}
