import React, { useEffect, useState } from "react";
import Feed from "./Feed";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { groupDetail } from "src/DAL/CommunityGroups/CommunityGroups";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function GroupsFeedsDetail() {
  const { state } = useLocation();
  const params = useParams();
  const classes = useStyles();

  const navigate = useNavigate();
  const [membersCount, setMembersCount] = useState(0);
  const [communityGroup, setCommunityGroup] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleMembers = () => {
    navigate(
      `/community-area/groups/group-members/${communityGroup?.community_group_slug}`
    );
  };

  const handleMyPosts = () => {
    navigate(`/my-posts`, { state: communityGroup });
  };
  const getGroupDetail = async () => {
    setIsLoading(true);
    try {
      const result = await groupDetail(params.id);
      if (result.code === 200) {
        setCommunityGroup(result.community_group);
        setMembersCount(result.community_group?.members.length);
        setIsLoading(false);
      } else {
        // enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getGroupDetail();
  }, []);
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-1"></div>
        <div className="col-10 mb-1 mt-1">
          <div>
            <div className="card-body">
              <div className="row">
                <div className="col-2">
                  <img
                    width={120}
                    height="auto"
                    src={s3baseUrl + communityGroup?.image?.thumbnail_1}
                    alt="Group Image"
                    className="img-fluid"
                  />
                </div>
                <div className="col-10">
                  <div>
                    <h5 className="card-title">{communityGroup.title}</h5>

                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: communityGroup.description,
                        }}></div>
                    </p>
                  </div>
                </div>
                <div className="col-12 text-end">
                  <button
                    className="small-contained-button"
                    onClick={handleMembers}>
                    Member List ({membersCount})
                  </button>
                  <button
                    className="small-contained-button ms-3"
                    onClick={handleMyPosts}>
                    My Posts
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
      <div className="mt-2">
        <Feed groupFeed={true} group={params.id} />
      </div>
    </>
  );
}
