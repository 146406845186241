import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { FormHelperText, Input, InputLabel } from "@mui/material";
import {
  _del_any_file_on_s3,
  _upload_any_single_file_on_s3,
} from "src/DAL/imageUpload/uploadImageOnS3";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "20%",
    marginTop: "20%",
  },
}));
export default function AvatarGallery({
  length,
  Index,
  questions,
  Image,
  setQuestions,
}) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  function handleAgreeDelete(value) {
    setDeleteDoc(value);
    setOpenDelete(true);
    console.log(value, "asdfhkaj");
  }
  const handleDelete = async () => {
    setOpenDelete(false);
    questions[Index].loading[deleteDoc] = "loading";
    setQuestions([...questions]);

    let postData = {
      files: [questions[Index].multiple_answers[deleteDoc]],
    };
    const result = await _del_any_file_on_s3(postData);

    if (result.code === 200) {
      questions[Index].answer[deleteDoc] = "";
      questions[Index].multiple_answers[deleteDoc] = "";
      questions[Index].loading[deleteDoc] = "";
      setQuestions([...questions]);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  async function onImageChange(e, ind) {
    const newFile = e.target.files[0];
    const formData = new FormData();
    questions[Index].loading[ind] = "loading";
    setQuestions([...questions]);
    formData.append("file", e.target.files[0]);
    const result = await _upload_any_single_file_on_s3(formData);
    if (result.code == 200) {
      console.log(result, "Upload", newFile);
      questions[Index].answer[ind] = newFile;
      questions[Index].multiple_answers[ind] = result?.path;
      questions[Index].loading[ind] = "";
      setQuestions([...questions]);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      questions[Index].loading[ind] = "";
    }
  }

  console.log(Image, "dsfkjhkajshdkjfa");
  return (
    <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      {[...Array(length)].map((_, index) => (
        <div
          key={index}
          style={{ position: "relative" }}
          // onMouseEnter={() => handleAvatarHover(index)}
          // onMouseLeave={handleAvatarLeave}
        >
          <Avatar
            sx={{ height: 100, width: 100 }}
            alt={`+`}
            src={Image[index] ? URL.createObjectURL(Image[index]) : "+"}
            variant="rounded"
          />

          {questions[Index] &&
          questions[Index]?.loading[index] &&
          questions[Index]?.loading[index] == "loading" ? (
            <CircularProgress
              color="primary"
              style={{
                position: "absolute",
                top: "25%",
                left: "30%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            ""
          )}
          {/* {hoveredIndex === index && ( */}
          {Image[index] ? (
            <div
              style={{
                position: "absolute",
                top: "-3%",
                right: "-17%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: "15px",
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: "50%",
                }}
                onClick={() => handleAgreeDelete(index)}
              />
            </div>
          ) : (
            <label htmlFor={`${Index}-contained-button-file${index}`}>
              <Input
                accept=".jpg, .jpeg, .png, .webp"
                id={`${Index}-contained-button-file${index}`}
                multiple
                type="file"
                name="image"
                required
                onChange={(e) => onImageChange(e, index)}
                style={{
                  display: "none",
                  position: "absolute",
                  top: "42%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />

              <IconButton
                style={{
                  position: "absolute",
                  top: "42%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                className="camera"
                color="primary"
                component="span"
                accept="image/*"
                name="image"
                type="file"
                // onClick={() => handleImageUpload(index)}
              >
                <PhotoCameraIcon sx={{ width: 50, height: 50 }} />
              </IconButton>
            </label>
          )}
          {/* )} */}
        </div>
      ))}
    </div>
  );
}
