import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { AddKimsReplyApi } from "src/DAL/selfImageApi/selfImageApi";
const Input = styled("input")({
  display: "none",
});

export default function AddKimsReply({ goalDetail }) {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFile, setAudio] = React.useState();
  const [inputs, setInputs] = React.useState("");
  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = () => {
    setAudio();
  };

  const handleChange = (event) => {
    setInputs(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("member_id", params.id);
    formData.append("module_type", "self_image");
    if (audioFile) {
      formData.append("audio_file", audioFile);
    }
    formData.append("message", inputs);
    const result = await AddKimsReplyApi(formData);
    if (result.code === 200) {
      setInputs("");
      setAudio();
      goalDetail();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div className="row w-100 div-style ms-0 pt-0">
          <div className="col-5">
            <p className="">Upload Audio</p>
            <FormHelperText className="pt-0">
              Audio mp3 (max 200mb)
            </FormHelperText>
          </div>
          <div className="col-2">
            {audioFile && (
              <DeleteIcon
                onClick={handldeDeleteAudio}
                className="mt-3 icon-color"
              />
            )}
          </div>
          <div className="col-5 text-end pt-2">
            <label htmlFor="audio">
              <Input
                accept="audio/mp3,audio/*;capture=microphone"
                id="audio"
                multiple
                name="audio"
                type="file"
                onChange={audioFileChange}
              />

              <Button
                className="small-contained-button"
                startIcon={<FileUploadIcon />}
                component="span">
                Upload
              </Button>
            </label>
          </div>
        </div>
        <p className="text-secondary">{audioFile && audioFile.name}</p>
      </div>
      <div className="col-12 mt-5">
        <FormControl fullWidth>
          <TextField
            id="outlined-multiline-flexible"
            label="Danielle's Reply"
            multiline
            rows={6}
            name="short_description"
            value={inputs}
            onChange={handleChange}
            required
          />
          <FormHelperText>Maximum limit 500 characters</FormHelperText>
        </FormControl>
      </div>

      <div className="text-end">
        <button className="small-contained-button" disabled={isLoading}>
          {isLoading ? "Submitting" : "Submit"}
        </button>
      </div>
    </form>
  );
}
