import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import { useNavigate, Link as RouterLink } from "react-router-dom";
import { IconButton, CircularProgress, OutlinedInput } from "@mui/material";
import TinyEditor from "../../components/ckeditor/Ckeditor";
import { AddGroupApi } from "src/DAL/CommunityGroups/CommunityGroups";
import { programmeListing } from "src/DAL/Programme/Programme";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});
const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function AddGroupsFeed() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");
  const [programsSelected, setProgramsSelected] = React.useState([]);
  const [programValue, setProgramValue] = React.useState([]);
  const [inputs, setInputs] = React.useState({
    title: "",
    groupStatus: true,
    image: {},
    autoPublish: true,
  });

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let selected_program_array = [];
    let selected_program_object = {};
    programsSelected.map((program) => {
      selected_program_object = {
        _id: program,
      };
      selected_program_array.push(selected_program_object);
    });

    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("description", detailDescriptionCk);
    formData.append("status", inputs.groupStatus);
    formData.append("auto_publish_post", inputs.autoPublish);

    if (file) {
      formData.append("main_image", inputs.image);
    }
    if (selected_program_array.length > 0) {
      formData.append("programs", JSON.stringify(selected_program_array));
    }
    console.log(...formData, "========formdata=======");
    if (detailDescriptionCk) {
      if (file) {
        setIsLoading(true);
        const result = await AddGroupApi(formData);
        if (result.code === 200) {
          //console.log(result, "result");
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(`/feed-groups`);
        } else {
          //console.log(result, "error case");
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      } else {
        enqueueSnackbar("Image is Required", { variant: "error" });
      }
    } else {
      enqueueSnackbar("Description is Required", { variant: "error" });
    }
  };
  const programmeListData = async () => {
    let result = await programmeListing();
    if (result.code == 200) {
      let programs = [];
      const data = result?.program?.map((group, index) => {
        if (group.action_by == "coach_user") {
          programs.push(group);
        }
      });
      console.log(programs, "my programs");
      setProgramValue(result?.program);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeProgram = (event) => {
    const {
      target: { value },
    } = event;
    setProgramsSelected(typeof value === "string" ? value.split(",") : value);
  };
  React.useEffect(() => {
    programmeListData();
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Add Group</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Group Title"
              required
              variant="outlined"
              fullWidth
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Group Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="groupStatus"
                value={inputs.groupStatus}
                label="Group Status *"
                onChange={handleChange}>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Auto Publish Post *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="autoPublish"
                value={inputs.autoPublish}
                label="Auto Publish Post *"
                onChange={handleChange}>
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label1">Programme</InputLabel>
              <Select
                labelId="demo-multiple-name-label1"
                id="demo-multiple-name"
                multiple
                value={programsSelected}
                onChange={handleChangeProgram}
                input={<OutlinedInput label="Programme *" />}
                MenuProps={MenuProps}>
                {programValue?.map((name) => (
                  <MenuItem key={name._id} value={name._id}>
                    {name.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-6">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-1">
                {file && (
                  <img className="image-border" src={file} height={50} />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span">
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>

          <div className="col-12 mt-3">
            <h4>Description *</h4>
            <TinyEditor
              setDetailDescription={setDetailDescriptionCk}
              detailDescriptionCk={detailDescriptionCk}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
