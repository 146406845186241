import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

// material
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/RecordNotFound";

import { ExerciseListingApi } from "src/DAL/exercise/exercise";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import ExerciseFilter from "./ExerciseFilterNew";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import FilteredChip from "src/components/FilteredChip";
import ToshowAddExercise from "./modalToshowAddExercise";
import { ExerciseTagesApi } from "src/DAL/exerciseType/exerciseType";
import Label from "src/components/Label";
import { useContentSetting } from "src/Hooks/ContentSettingState";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});
const tabs = {
  all: "",
  asc: "asc",
  close: "desc",
  trash: "trash",
};
function ToshowExercise({ open, setOpen, handleChange }) {
  const handleCloseDialog1 = () => {
    setOpen(false);
    localStorage.removeItem("WorkoutExerciseListSearch");
    setFilterState(EMPTY_FILTER);
    setFilterCount(0);
    setFilterName("");
  };
  const { userInfo } = useContentSetting();

  const [selectedTab, setSelectedTab] = useState(tabs.asc);
  const [selectedExerciseTags, setSelectedExerciseTags] = useState([]);
  const [selectedDifficultyTags, setSelectedDifficultyTags] = useState([]);
  const [selectedMainMuscleTags, setSelectedMainMuscleTags] = useState([]);
  const id = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [exercise, setExercise] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [oldFilterName, setOldFilterName] = useState("");

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [exercisetag, setExerciseTag] = useState([]);
  const [difficulty, setDifficulty] = useState([]);
  const [main_muscle, setMainMuscle] = useState([]);
  const [conditionMetOnce, setConditionMetOnce] = useState(false);

  const EMPTY_FILTER = {
    exercises: [],
    mainMuscle: [],
    difficulty: [],
    search_text: "",
    exercise_type: null,
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState({});
  // const [filterStateUpdated, setFilterStateUpdated] = useState({EMPTY_FILTER});
  const [openDetails, setOpenDetails] = useState(false);
  const searchFunction = () => {
    setPage(0);
    // setPageCount(1);
    filterState.search_text = filterName;
    getExerciseList(filterState);
    localStorage.setItem(
      "WorkoutExerciseListSearch",
      JSON.stringify(filterState)
    );

    handleCloseFilterDrawer();
    setIsLoading(true);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    // setPageCount(1);

    getExerciseList(data);
    setFilterState(data);
    localStorage.setItem("WorkoutExerciseListSearch", JSON.stringify(data));
  };
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (Math.ceil(scrollTop + clientHeight) == scrollHeight) {
      fetchMoreData();
    }
  };
  const handleClearFilter = () => {
    let clear_data = { ...EMPTY_FILTER };
    console.log(clear_data, "dslkjfjajsdklfka");
    setPage(0);
    // setPageCount(1);
    setFilterName("");
    setFilterState(clear_data);

    getExerciseList(clear_data);
    handleCloseFilterDrawer();
    setIsLoading(true);
    localStorage.removeItem("WorkoutExerciseListSearch");
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
    setFilterState(filterStateUpdated);
  };
  const handleOpenAddExercise = () => {
    setOpenDetails(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleSelectExercise = (value) => {
    // console.log(value, "dsfhkjahsjkdhfkjah");
    handleChange(value);
    // const list = [...AddArray];

    // list[Index]["exercise"] = value;
    // list[Index]["description"] = value?.instructions;
    // setAddArray(list);
    handleCloseDialog1();
    localStorage.removeItem("WorkoutExerciseListSearch");
    setFilterState(EMPTY_FILTER);
    setFilterCount(0);
    setFilterName("");
  };
  const fetchMoreData = () => {
    console.log("Fetching more data...");

    setPage((prev) => prev + 1);
  };
  useEffect(() => {
    if (filterName !== oldFilterName) {
      setOldFilterName(filterName);
    }
  }, [filterName]);
  const getExerciseList = async (filterData) => {
    setOldFilterName(filterName);
    let postdata = {
      difficulty: filterData.difficulty,
      exercise: filterData.exercises,
      main_muscle: filterData.mainMuscle,
      exercise_type: filterData?.exercise_type
        ? filterData?.exercise_type._id
        : "",
    };
    let result = await ExerciseListingApi(
      page,
      rowsPerPage,
      filterName,
      postdata,
      selectedTab
    );
    if (result.code == 200) {
      let filtersCount =
        filterData?.difficulty.length +
        filterData?.exercises.length +
        filterData?.mainMuscle.length;
      setFilterCount(
        filterData?.difficulty.length +
          filterData?.exercises.length +
          filterData?.mainMuscle.length
      );
      if (filterData?.status) {
        setFilterCount((values) => values + 1);
        filtersCount = filtersCount + 1;
      }
      if (filterData?.exercise_type) {
        setFilterCount((values) => values + 1);
        filtersCount = filtersCount + 1;
      }
      let chipData = { ...filterData };
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setFilterStateUpdated(chipData);
      setIsLoading(false);
      const newData = result?.exercises;
      setTotalCount(result.total_exercises);
      // Check if newData is an array and has data
      if (!filtersCount && !filterCount && !filterName) {
        setConditionMetOnce(false);
      }
      if ((filtersCount || filterCount || filterName) && !conditionMetOnce) {
        setExercise(result?.exercises);
        setPage(0);
        setConditionMetOnce(true);
        console.log(conditionMetOnce, "sadlkjfjsdlklklka");
        return;
      } else {
        let mergedData;
        if (page === 0) {
          mergedData = newData;
        } else {
          mergedData = [...exercise, ...newData];
        }
        // const mergedData = [
        //   ...exercise,
        //   ...newData.filter(
        //     (item) =>
        //       !exercise.find((existingItem) => existingItem._id === item._id)
        //   ),
        // ];
        // if (filterName !== oldFilterName) {
        //   mergedData = newData;
        // } else {
        //   mergedData = [
        //     ...exercise,
        //     ...newData.filter(
        //       (item) =>
        //         !exercise.find((existingItem) => existingItem._id === item._id)
        //     ),
        //   ];
        // }
        let sortedMergedData = [...mergedData];
        let sortedMergedDataagain = [...mergedData];

        if (selectedTab === "asc") {
          sortedMergedData.sort((a, b) => {
            // Customize the comparison logic based on your data structure
            // Assuming you want to sort based on some property like title
            return a.title.localeCompare(b.title);
          });
        } else if (selectedTab === "desc") {
          sortedMergedData.sort((a, b) => {
            // Customize the comparison logic based on your data structure
            // Assuming you want to sort based on some property like title
            return b.title.localeCompare(a.title);
          });
        }
        if (filterName) {
          const word = filterName.toLowerCase();
          console.log(word, "sdkjfhksjad");
          sortedMergedData.sort((a, b) => {
            const startsWithA = a.title.toLowerCase().startsWith(word);
            const startsWithB = b.title.toLowerCase().startsWith(word);

            // If only one of them starts with the search term, that one should come first
            if (startsWithA && !startsWithB) {
              return -1;
            }
            if (!startsWithA && startsWithB) {
              return 1;
            }
            // If neither or both start with the search term, sort alphabetically
            return a.title.localeCompare(b.title);
          });
        }

        if (filterName !== "") {
          setExercise(sortedMergedDataagain);
        } else {
          setExercise(sortedMergedData);
        }
      }

      // setExercise(sortedMergedData);
      // setTotalPages(result.total_pages);
    }
  };
  const getExerciseTagesListing = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      const result = await ExerciseTagesApi();
      if (result.code === 200) {
        setExerciseTag(result?.tags?.exercise);
        setDifficulty(result?.tags?.difficulty);
        setMainMuscle(result?.tags?.main_muscle);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getExerciseTagesListing();
  }, []);
  useEffect(() => {
    if (!openDetails && open) {
      let filter_data = EMPTY_FILTER;
      let find_filter = localStorage.getItem("WorkoutExerciseListSearch");
      if (find_filter) {
        filter_data = JSON.parse(find_filter);
        if (filter_data.search_text) {
          setFilterName(filter_data.search_text);
        }
      }
      setFilterState(filter_data);
      setFilterStateUpdated(filter_data);

      getExerciseList(filter_data);
    }
    // filterState.search_text = filterName;
    // getExerciseList(filterState);
  }, [page, rowsPerPage, open, openDetails, selectedTab]);
  useEffect(() => {
    // Delay execution of getExerciseList only when filterName changes
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("WorkoutExerciseListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      // if (filter_data.search_text) {
      //   setFilterName(filter_data.search_text);
      // }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    const timer = setTimeout(() => {
      setPage(0);
      getExerciseList(filter_data);
    }, 500);

    // Clean up function to clear the timer when filterName changes again or component unmounts
    return () => clearTimeout(timer);
  }, [filterName]);
  useEffect(() => {
    setIsLoading(true);
    setPage(0);
    setExercise([]);
  }, [selectedTab, open]);
  // console.log(filterStateUpdated, "sdkjfhkjdhsa");

  // if (isLoading === true) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }

  return (
    <>
      {/* New to solve delete issue  */}
      <Dialog
        open={open}
        onClose={handleCloseDialog1}
        // scroll="body"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            minHeight: "550px",
          },
        }}>
        <div className="d-flex justify-content-between align-items-center ">
          <div className="row w-100  ">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <DialogTitle> Exercises</DialogTitle>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className=" me-1">
                <div className="text-end ">
                  <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    sx={{ marginRight: 1, width: 240, mt: 3 }}
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                  <button
                    className="small-contained-button me-2  mt-4 mb-4"
                    onClick={handleOpenFilterDrawer}
                    style={{ position: "relative" }}>
                    Filters &nbsp;&nbsp;{" "}
                    <Icon width={19} height={19} icon={roundFilterList} />
                    <span
                      // className="small-contained-button"
                      style={{
                        position: "absolute",
                        bottom: "59%",
                        right: "8%",
                      }}>
                      {filterCount ? (
                        <div className="dot-filter-count">{filterCount}</div>
                      ) : (
                        <div></div>
                      )}
                    </span>
                  </button>
                  <button
                    className="small-contained-button me-2  mt-4 mb-4"
                    onClick={handleOpenAddExercise}>
                    Add Exercise
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DialogContent sx={{ overflow: "hidden" }}>
          {/* <DialogContent onScroll={(event) => handleScroll(event)}> */}
          {isLoading ? (
            <CircularProgress className={classes.loading} color="primary" />
          ) : (
            <div className="">
              <CustomDrawer
                isOpenDrawer={filterDrawerState}
                onOpenDrawer={handleOpenFilterDrawer}
                onCloseDrawer={handleCloseFilterDrawer}
                pageTitle="Filters"
                componentToPassDown={
                  <ExerciseFilter
                    filterData={filterState}
                    setFilterData={setFilterState}
                    searchSubmitFilter={searchFunction}
                    handleClearFilter={handleClearFilter}
                    exercise={exercisetag}
                    difficulty={difficulty}
                    main_muscle={main_muscle}
                  />
                }
              />
              <ToshowAddExercise open={openDetails} setOpen={setOpenDetails} />
              {/* <div className="row mobile-margin display-flex mt-2">
                <div className="col-lg-12 col-sm-12 text-end ">
                  <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    sx={{ marginRight: 2, width: 240 }}
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                  <button
                    className="small-contained-button me-2 mt-0 mb-4"
                    onClick={handleOpenFilterDrawer}
                  >
                    Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
                  </button>
                </div>
                <div className="col-lg-12 col-sm-12 d-flex justify-content-end"></div>
              </div> */}

              <div className="row">
                {exercise.length < 1 ? (
                  <RecordNotFound title="Exercises" />
                ) : (
                  <>
                    <div className="d-flex mb-1">
                      <div className="ms-3 me-2 pointer">
                        <Label
                          onClick={() => {
                            if (filterName !== "") {
                            } else {
                              setSelectedTab(tabs.asc);
                            }
                          }}
                          // onClick={() => console.log("fksjdfha")}
                          variant={
                            filterName !== ""
                              ? ""
                              : selectedTab === tabs.asc
                              ? "ghost"
                              : ""
                          }
                          style={{
                            width: 50,
                            cursor: filterName !== "" ? "default" : "pointer",
                          }}
                          color="info">
                          A-Z
                        </Label>
                      </div>
                      <div className="me-2 pointer">
                        <Label
                          onClick={() => {
                            if (filterName !== "") {
                            } else {
                              setSelectedTab(tabs.close);
                            }
                          }}
                          variant={
                            filterName !== ""
                              ? ""
                              : selectedTab === tabs.close
                              ? "ghost"
                              : ""
                          }
                          style={{
                            width: 50,
                            cursor: filterName !== "" ? "default" : "pointer",
                          }}
                          color="info">
                          Z-A
                        </Label>
                      </div>
                    </div>

                    <InfiniteScroll
                      className="w-100 m-auto"
                      dataLength={exercise.length}
                      next={fetchMoreData}
                      height="calc(100vh - 200px)"
                      hasMore={exercise.length < totalCount ? true : false} // Set this to 'false' when you have loaded all available data.
                      loader={<h4 className="text-center">Loading...</h4>}>
                      <div className="row w-100 m-auto">
                        {exercise.map((x, i) => {
                          return (
                            <>
                              <div
                                className="col-lg-3 col-md-3 col-sm-12 mb-4"
                                key={i}
                                onClick={() => handleSelectExercise(x)}>
                                <div className="card mt-1 exercise-cards-shadow ms-1 cursor h-100">
                                  <img
                                    src={s3baseUrl + x?.image?.thumbnail_1}
                                    // src="https://images.pexels.com/photos/4473622/pexels-photo-4473622.jpeg?cs=srgb&dl=pexels-ketut-subiyanto-4473622.jpg&fm=jpg"
                                    className="card-img-top pods-image"
                                    alt="workout"
                                  />
                                  <div className="card-body">
                                    <div className="row d-flex align-items-end h-100">
                                      <div className="col-12 ">
                                        <div
                                          className="h2-heading fw-bold "
                                          // onClick={() => handleNavigateDetail(value)}
                                        >
                                          {htmlDecode(x?.title)}
                                        </div>
                                      </div>
                                      {x.action_by &&
                                        x.action_by == "coach_user" &&
                                        x.action_id == userInfo?._id && (
                                          <div className="col-12 text-end">
                                            <Chip
                                              label={"Added By You"}
                                              variant="contained"
                                              color={"primary"}
                                              sx={{
                                                fontSize: "10px",
                                                height: "auto",
                                                width: "auto",
                                                borderRadius: "4px",
                                              }}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </>
                )}
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          {/* <Button className="model-button-hover" onClick={handleCloseDialog1}>
            close
          </Button> */}
          {/* <Button
            className="model-button-hover"
            onClick={() =>
              handleOk(
                selectedExerciseTags,
                selectedDifficultyTags,
                selectedMainMuscleTags
              )
            }
          >
            Ok
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ToshowExercise;
