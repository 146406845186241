import { filter } from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
// components
import Label from "../../components/Label";
import SearchNotFound from "../../components/SearchNotFound";
import { get_root_value } from "src/utils/domUtils";
import {
  UserListHead,
  UserListToolbar,
} from "../../components/_dashboard/user";
import DeletedModal from "src/components/modal/DeleteModal";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "src/components/CustomPopover";
import {
  goalIncompleteApi,
  goalMemberListingApi,
  goalSaveAndCloseAddApi,
} from "src/DAL/MemberGoalStatement/MemberGoalStatement";
import CustomConfirmationModal from "src/components/modal/CustomConfirmationModal";
import moment from "moment";
import {
  selfIncompleteApi,
  selfSaveAndCloseAddApi,
} from "src/DAL/MemberSelfImage/MemberSelfImage";
import { selfMemberListingApi } from "src/DAL/MemberJournal/MemberJournal";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "profile", label: "Profile", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "goalStatus", label: "Journal Prompts Status", alignRight: false },
  {
    id: "goal_statement_completed_date",
    label: "Journal Prompts Completed Date",
    alignRight: false,
  },
  { id: "status", label: "Status", alignRight: false },
  { id: "goal", label: "Goal", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function JournalMember() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [UserId, setUserId] = useState(false);
  const [userList, setUserList] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openIncompleteConfirmation, setOpenIncompleteConfirmation] =
    useState(false);
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleEdit = (value) => {
    navigate(`/member-completed-journalPrompt/${value.id}`, {
      state: value,
    });
  };
  const navigateByName = (value) => {
    navigate(`/member-completed-goalstatements/${value.id}`, {
      state: value,
    });
  };

  const getMemberListing = async () => {
    try {
      setIsLoading(true);
      const result = await selfMemberListingApi();
      if (result.code === 200) {
        const data = result.member_array.map((member) => {
          return {
            id: member?._id,
            name: member?.first_name + " " + member?.last_name,
            email: member?.email,
            status: member?.status,
            programs: member?.program,
            avatarUrl: member?.profile_image,
            goal: member?.journal_prompt_status,
            goalStatus: member?.journal_prompt_completed_status,
            goal_statement_completed_date:
              member?.journal_prompt_completed_date,
            object: member,
          };
        });
        setUserList(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  const handleOpenConfirmation = (e) => {
    setUserId(e);
    setOpenConfirmation(true);
  };

  const handleOpenIncompleteConfirmation = (e) => {
    setUserId(e);
    setOpenIncompleteConfirmation(true);
  };

  const saveAndClose = async () => {
    setOpenConfirmation(false);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("member", UserId?.id);
    formData.append("save_and_close_status", true);
    formData.append("module_type", "journal_prompt");

    const result = await selfSaveAndCloseAddApi(formData);
    if (result.code == 200) {
      setIsLoading(false);
      getMemberListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const markIncomplete = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("member", UserId?.id);
    formData.append("module_type", "journal_prompt");
    const result = await selfIncompleteApi(formData);
    if (result.code == 200) {
      setOpenIncompleteConfirmation(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      getMemberListing();
    } else {
      setOpenIncompleteConfirmation(false);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Save & Close",
      icon: "akar-icons:edit",
      handleClick: handleOpenConfirmation,
    },
    {
      label: "Incomplete",
      icon: "akar-icons:edit",
      handleClick: handleOpenIncompleteConfirmation,
    },
  ];
  React.useEffect(() => {
    getMemberListing();
  }, []);

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <DeletedModal title="member" id={UserId} open={open} setOpen={setOpen} />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Member Journal Prompts</h2>
          </div>
        </div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        ></Stack>
        <Card style={{ overflowX: "auto" }}>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      name,
                      email,
                      status,
                      avatarUrl,
                      goal,
                      goalStatus,
                      goal_statement_completed_date,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{
                          " td, th, tr": {
                            borderBottom: `1px solid ${get_root_value(
                              "--text-secondary-color"
                            )}`,
                          },
                        }}
                      >
                        <TableCell align="left">{i + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              variant="subtitle2"
                              noWrap
                              onClick={() => navigateByName(row)}
                              className="anchor-style"
                            >
                              {Capitalize(htmlDecode(name))}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          {avatarUrl ? (
                            <Avatar alt={name} src={s3baseUrl + avatarUrl} />
                          ) : (
                            <Avatar alt={name} src={name} />
                          )}
                        </TableCell>
                        <TableCell align="left">{htmlDecode(email)}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (goalStatus === false && "error") || "success"
                            }
                          >
                            {goalStatus == true ? "Completed" : "Incomplete"}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          {goal_statement_completed_date == "Invalid date" ||
                          goal_statement_completed_date == ""
                            ? "N/A"
                            : goalStatus == false
                            ? ""
                            : moment(goal_statement_completed_date).format(
                                "DD/MM/YYYY"
                              )}
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(status === false && "error") || "success"}
                          >
                            {status == true ? "Active" : "Inactive"}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(goal === false && "error") || "success"}
                          >
                            {goal === true ? "Unlock" : "Lock"}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <CustomPopover menu={MENU_OPTIONS} data={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound === true && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
      <CustomConfirmationModal
        message="Are you sure you want save and close?"
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        handleAgree={saveAndClose}
      />
      <CustomConfirmationModal
        message="Are you sure you want to mark this incomplete?"
        open={openIncompleteConfirmation}
        setOpen={setOpenIncompleteConfirmation}
        handleAgree={markIncomplete}
      />
    </>
  );
}
