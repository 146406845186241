import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { get_root_value } from "src/utils/domUtils";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Tooltip,
  Chip,
} from "@mui/material";

import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { Pages } from "@mui/icons-material";
import moment from "moment";
import SearchNotFound from "src/components/SearchNotFound";
import CreatePost from "./CreatePost";
import { userFeedGroupListing, userFeedListing } from "src/DAL/member/Member1";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { delete_feed_action } from "src/DAL/Community/Community";
import Label from "src/components/Label";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false },
  { id: "number", label: "#", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "publish_status", label: "Publish Status", alignRight: false },
  { id: "createdAt", label: "Date", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user?.description
          ?.toLowerCase()
          .indexOf(query?.toLowerCase().trim()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const USERLIST = [
  {
    id: 1,
    question: "Current Average Working Hours Per Week?",
    avatarUrl: "/static/mock-images/avatars/avatar_3.jpg",
    status: "active",
  },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let dumyData = [
  {
    id: 1,
    description: "Basic Information",
    submission_status: "pending",
    description:
      "Cycling can help to protect you from serious diseases such as stroke, heart attack, some cancers, depression, diabetes, obesity and arthritis.",
  },
];
export default function MyPosts() {
  const params = useLocation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [userList, setUserList] = useState(dumyData);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [member, setData] = useState([]);
  const [feedType, setFeedType] = useState("general");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [UserId, setUserId] = useState(false);
  const navigate = useNavigate();
  const [selectedFeed, setSelectedFeed] = useState({});
  const [formAction, setFormAction] = useState("ADD");
  const { state } = useLocation();
  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };
  const { userInfo } = useContentSetting();
  const getFormListing = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);

      const result = state
        ? await userFeedGroupListing(page, rowsPerPage, "", state?._id)
        : await userFeedListing(page, rowsPerPage, "");
      if (result.code === 200) {
        setData(result.feeds);
        setIsLoading(false);
        setUserList(result.feeds);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleEdit = (value) => {
    console.log(value, "valueeeeeee");
    setSelectedFeed(value);
    setOpenCreateModal(true);
    setFormAction("EDIT");
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_feed_action(deleteDoc._id);
    if (result.code === 200) {
      enqueueSnackbar("Post deleted successfully", { variant: "success" });
      getFormListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  useEffect(() => {
    getFormListing();
  }, [Pages, rowsPerPage]);

  const MENU_OPTIONS2 = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-lg-6 col-sm-12">
            <h2>My Posts</h2>
          </div>
        </div>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        ></Stack>

        <Card style={{ overflowX: "auto" }}>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      name,
                      publish_status,
                      status,
                      rejection_reason,
                      description,
                      submission_status,
                      createdAt,
                      receiver_type,
                      program,
                      send_to,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell>
                          <CustomPopoverSection
                            menu={MENU_OPTIONS2}
                            data={row}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {description}
                            </Typography>
                          </Stack>
                        </TableCell>
                        {publish_status == "rejected" ? (
                          <TableCell
                            component="th"
                            scope="row"
                            className="publish-status"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Tooltip
                                title={rejection_reason && rejection_reason}
                                arrow
                                placement="top"> */}
                              <Label
                                title={rejection_reason}
                                variant="ghost"
                                color={
                                  publish_status === "rejected"
                                    ? "error"
                                    : publish_status === "approved"
                                    ? "success"
                                    : "warning"
                                }
                              >
                                {publish_status === "rejected"
                                  ? "Rejected"
                                  : publish_status === "approved"
                                  ? "Approved"
                                  : "Pending"}
                              </Label>
                              {/* </Tooltip> */}
                            </Stack>
                          </TableCell>
                        ) : (
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Label
                                variant="ghost"
                                color={
                                  publish_status === "rejected"
                                    ? "error"
                                    : publish_status === "approved"
                                    ? "success"
                                    : "warning"
                                }
                              >
                                {publish_status === "rejected"
                                  ? "Rejected"
                                  : publish_status === "approved"
                                  ? "Approved"
                                  : "Pending"}
                              </Label>
                            </Stack>
                          </TableCell>
                        )}

                        <TableCell component="th" scope="row">
                          {moment(createdAt).format("ddd, MMM DD, YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
      <CreatePost
        setFeedType={setFeedType}
        feedType={feedType}
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        getFeedsListing={getFormListing}
        userInfo={userInfo}
        selectedFeed={selectedFeed}
        formAction={formAction}
        setFormAction={setFormAction}
        handleSuccessUpdate={getFormListing}
      />
    </>
  );
}
