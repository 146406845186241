import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  AddMemberstoGroupApi,
  membersToAddInCommunityGroup,
} from "src/DAL/CommunityGroups/CommunityGroups";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function AddGroupMembers({
  params,
  handleCloseFilterDrawerMember,
  getMemberListing,
}) {
  const [personName, setPersonName] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [member, setMember] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const getMember = async () => {
    const result = await membersToAddInCommunityGroup(params.slug, serchText);
    if (result.code === 200) {
      setPersonName(result.members);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    let selected_member_array = [];
    let selected_member_object = {};
    member.map((member) => {
      selected_member_object = {
        _id: member._id,
      };
      selected_member_array.push(selected_member_object);
    });
    const postData = { members: selected_member_array };
    formData.append("members", JSON.stringify(selected_member_array));
    console.log(selected_member_array, "=========formdata======");
    if (selected_member_array.length > 0) {
      setIsLoading(true);

      const result = await AddMemberstoGroupApi(postData, params.slug);

      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        getMemberListing();
        handleCloseFilterDrawerMember();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("Member is Required", { variant: "error" });
    }
  };
  useEffect(() => {
    getMember();
  }, [serchText]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container new-memories px-4">
      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
        <Autocomplete
          multiple
          id="tags-outlined"
          options={personName || []}
          getOptionLabel={(option) =>
            option.first_name + " (" + option.email + ")"
          }
          filterSelectedOptions
          value={member}
          onChange={(event, newValue) => {
            setMember(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Members*"
              placeholder="Members*"
              onChange={handleSearch}
            />
          )}
        />
      </div>
      <div className="col-l2 text-end mt-3">
        <button className="small-contained-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}
