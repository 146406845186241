import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import moment from "moment";
import { htmlDecode } from "src/utils/convertHtml";
import { questionReplyHistory } from "src/DAL/MemberSelfImage/MemberSelfImage";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export const AnswerHistory = ({ data, member_id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [replies, setReplies] = useState([]);

  const historyDetail = async () => {
    setIsLoading(true);
    let postData = {
      question_id: data._id,
      member_id,
      module_type: "self_image",
    };
    const result = await questionReplyHistory(postData);
    if (result.code == 200) {
      setReplies(result.answer_stats);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  React.useEffect(() => {
    historyDetail();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row answer-history">
        <div className="col-12 mt-3">
          <p className="heading">Question :</p>
          <p className="mb-4">{htmlDecode(data.question)}</p>
        </div>
        {replies.length < 1 ? (
          <h6>No Answer</h6>
        ) : (
          replies.map((reply) => {
            return (
              <>
                <div className="col-12 mt-1">
                  <div className="d-flex justify-content-between">
                    <p className="heading">Answer :</p>
                    <div className="me-3 mt-1 text-muted">
                      {moment(reply.createdAt).format("YYYY-MM-DD")}
                    </div>
                  </div>
                  <p className="">{htmlDecode(reply.answer)}</p>
                </div>
              </>
            );
          })
        )}
      </div>
    </div>
  );
};
